import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, useWindowDimensions, ScrollView, Text } from 'react-native';
//import { Input, Button } from 'react-native-elements';
import { useForm, Controller } from 'react-hook-form';
import TextField from '../controls/TextBox';
import NumberField from '../controls/NumberField';
import PaperDates from '../controls/datePicker-paperDates';
import { DropdownControl } from '../controls/Dropdown-sharingan-rn';
import ButtonControl from '../controls/Button';
import { createItem, updateItem } from '../service/dataOperations';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ToastMessage from '../controls/Toast';
import formCustomStyles from './formStyles';
import { getQuantityCalculation } from '../controls/BusinessLogic';

interface PaperFormProps {
  formMode: string;
  fields: Array<any>;
  selectedRow: Array<any>;
  closeForm: () => void;
  siteId:string;
  logTitle:string;
  updateFormState:()=>void;
  ItemCreated:(Item:Array<any>)=>void;
}

const PaperForm: React.FC<PaperFormProps> = React.memo((props) => {
  
  const [formStyle, setFormStyle] = useState(styles.formContainerMobile);
  const [formMode, setFormMode] = useState(props.formMode);
  const [Quantity, setQuantity] = useState(null);
  const [QuantityTonne, setQuantityTonne] = useState(null);
  const [fields, setFields] = useState(props.fields);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [itemId, setItemId] = useState(null);
  const [d, setD] = useState<any>('');
  let item: any;
  //let fields = props.fields;
  useEffect(() => {
  if (formMode === 'Edit' || formMode === 'View') {
    item = props.selectedRow[0] && props.selectedRow[0];
    let Allfields = item && props.fields.map((field) => {
      // if(field.subType === 'date')
      // {
      //   console.log("Selected Date: "+ item[field.key]);
      // }
      //const defaultValue = field.subType === 'date'? item[field.key] && returnDate(item[field.key]):item[field.key];
      const defaultValue = item[field.key] && item[field.key];
      return { ...field, defaultValue };
    });
    setFields(Allfields);
    setLoadForm(true);
    setItemId(item.ID);
  }
  else if (formMode === 'New') { setLoadForm(true) }
}, [formMode, props.selectedRow, props.fields]);
  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm();

  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  function MonthYear(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const month = adjustedDate.toLocaleString('en-US', { month: 'short' });
      const year = adjustedDate.getFullYear();
      return month+"-"+year;
    } else {
      return null;
    }
  }
  const onSubmit = async (d: any) => {
    const MonthANDYear = d.Date && d.Date!= null ? MonthYear(d.Date) : null;
    Object.keys(d).map((f:any) => {
      if (f === "QuantityTonne") {
        d.QuantityTonne = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityGallons") {
        d.QuantityGallons = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityLiters") {
        d.QuantityLiters = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
      else if (f === "QuantityGallon") {
        d.QuantityGallon = d.Quantitym3 && getQuantityCalculation(props.logTitle, d.Quantitym3);
      }
    });
    const Month = MonthANDYear === null? null : MonthANDYear.split('-')[0];
    const Year = MonthANDYear === null? null : MonthANDYear.split('-')[1];
    const data = { ...d, Month, Year};
    //setD(JSON.stringify(data));
    let oItem = data;
    const result = Object.keys(data).map((key) => {
      const column = fields.find((c) => c.key === key);
      if (!column) {
        return null;
      }
      const value = data[key];
      const dataType = column.datatype.toLowerCase();
      if(dataType === 'datetimes'){
        delete oItem.key;
        oItem = { ...oItem, key: value };
      }
      const formattedValue =
        dataType === 'strings'
          ? value
          : dataType === 'numbers'
          ? Number(value)
          : dataType === 'datetimes'
          ? value
          : null;

      return {
        column_Name: column.key,
        [`${dataType}_Value`]: formattedValue,
        dataype: dataType,
      };
    }).filter((x) => x !== null);

    let response:any;
    if (formMode === 'Edit') {
      const accessToken:any = await getAccessToken();
      response = await updateItem(props.logTitle, accessToken, clientAPIURL, props.siteId, itemId, result);
      
    } else {
      const accessToken:any = await getAccessToken();
      response = await createItem(props.logTitle, accessToken, clientAPIURL, props.siteId, result);
    }

      //if(response.status === 401 || response.statusText === "Unauthorized" || response.statusText === "Bad Request")
      if(!response)
      {
        setToastText("Operation Failed: You are not authorized to perform this action.")
        setToastVisibility(true);
    }
    if (response) {
      setToastText("Your changes are saved.")
      setToastVisibility(true);
      //const dataArray = [data];
      setTimeout(function () {
        if (formMode === 'Edit') {
          //const createdItem = oItem && {...oItem, "ID": itemId, "key": itemId };
          if (oItem) {
            oItem["ID"] = itemId
            oItem["key"] = itemId
          }
          const createdItem = oItem
          props.ItemCreated(createdItem);
        }
        else {
          const createdItem = oItem //&& { ...oItem, "ID": response, "key": response };
          if (oItem) {
            oItem["ID"] = response
            oItem["key"] = response
          }
          props.ItemCreated(createdItem);
        }
      }, 2000)
    }
  };
  const invalidNumber = () => {
    setToastText("Invaid Entry: Please enter valid number to numerical column.")
    setToastVisibility(true);
  }
function returnDate(value:Date){
  console.log("value: "+ value);
  if (value) {
    try{
    const date = new Date(`${value.split('/')[0]}/${value.split('/')[1]}/${value.split('/')[2]}`);
    console.log(date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      console.log("formattedDate: " + formattedDate);
    return formattedDate
    }
    catch(ex){
      return value
    }
  }
  else return value

}
const handleClose = ()=>{
  props.closeForm()
}

const handleToastDismiss = () => {
  setToastVisibility(false);
};
  return (
    loadForm === false ? null :
      <View style={formStyle}>
        <ScrollView>
          <>
            <View style={[{ flex: 1, margin: 10 }, styles.buttonsContainer]}>
              {
                formMode === "View"
                  ?
                  // <View style={[{ flex: 1 }, styles.buttonsContainer]}>
                  //   <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' />
                  // </View>
                  <View>
                  <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  </View>
                  :
                  <ScrollView horizontal>
                    <View style={styles.buttonsContainer}>
                      <View style={{  }}>
                        <ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' />
                      </View>
                      <View style={{  }}>
                        <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' />
                      </View>
                    </View>
                  </ScrollView>
              }
            </View>
            <View style={formCustomStyles.allFieldsContainer}>
              {fields.map((field, index) => {
                if (field.hidden === true)
              {
                return null
              }
              else{
              switch (field.subType) {
                case 'text':
                  return (
                    // <Text>{field.key}</Text>
                    <View key={index} style={[formCustomStyles.fieldContianer]}>
                    <TextField
                      key={field.key.toString()}
                      editable={formMode === 'View' ? false : true}
                      field={field}
                      control={control}
                      styles={styles.input}
                      Ctrl={Controller}
                      errors={errors}
                      //ref={register}
                    />
                    </View>
                  );
                    case 'choice':
                      return (
                        <View key={index} style={[formCustomStyles.fieldContianer]}>
                        <DropdownControl
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          field={field}
                          control={control}
                          Ctrl={Controller}  
                          errors={errors}                        
                        /></View>
                      );
                    case 'float':
                      return (
                        <View key={index} style={[formCustomStyles.fieldContianer]}>
                        <NumberField
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          field={field}
                          control={control}
                          styles={styles.input}
                          Ctrl={Controller}
                          errors={errors}
                          logTitle={props.logTitle}
                          fields={fields}
                          setFields={setFields}
                          invalidNumber={invalidNumber}
                          //ref={register}
                        /></View>
                      );
                      case 'integer':
                      return (
                        <View key={index} style={[formCustomStyles.fieldContianer]}>
                        <NumberField
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          field={field}
                          control={control}
                          styles={styles.input}
                          Ctrl={Controller}
                          errors={errors}
                          logTitle={props.logTitle}
                          fields={fields}
                          setFields={setFields}
                          invalidNumber={invalidNumber}
                          //ref={register}
                        /></View>
                      );
                    case 'date':
                      return (
                        <View key={index} style={[formCustomStyles.fieldContianer]}>
                        <PaperDates
                          key={field.key.toString()}
                          field={field}
                          editable={formMode === 'View' ? false : true}
                          control={control}
                          styles={styles.date}
                          Ctrl={Controller}
                          errors={errors}
                          //ref={register}
                        /></View>
                      );
                    default:
                      return null//<View key={index} style={[formCustomStyles.fieldContianer]}><Text>{field.key}</Text></View>;
                  }
                }
              }
            )}
              </View>
              <View style={{margin:10}}>
                {
                  formMode==="View"
                  ?
                  // <View style={[{flex:1}, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  :
                  <ScrollView horizontal style={{width:'100%'}}>
                    <View style={styles.buttonsContainer}>
                      <View style={{ }}><ButtonControl icon="content-save-edit" mode="contained" onPress={handleSubmit(onSubmit)} Title='Submit' /></View>
                      <View style={{ }}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                    </View>
                  </ScrollView>
                }
            {/* <Input placeholder={''} value={d} /> */}
          </View>
          <View>
            <ToastMessage
              show={toastVisibility}
              actionLabel="Dismiss"
              toastMessage={toastText}
              duration={3000}
              onDismiss={handleToastDismiss}
            />
          </View>
        </>
      </ScrollView>
        </View>
      );
    });
    
    const styles = StyleSheet.create({
      container: {
        //justifyContent: 'center',
        //alignItems: 'center',
        paddingLeft:'5%',
        paddingRight:'5%',

        flex: 1,
        padding: 16,
        gap: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      },
      formContainerPC: {
        paddingLeft: '25%',
        paddingRight: '25%',
      },
      formContainerMobile: {
        borderWidth: 0.5, // Set the border width
        borderColor: 'gray', // Set the border color
        borderRadius: 5, // Set the border radius (optional)
        backgroundColor:'#FFFFFF',
        shadowColor: 'blue', // Set the shadow color
        shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
        shadowOpacity: 0.5, // Set the shadow opacity
        shadowRadius: 4, // Set the shadow radius
        elevation: 4,
        //marginLeft: '5%',
        //marginRight: '5%',
        //paddingLeft: '2%',
        //paddingRight: '2%',
      },
      input: {
        backgroundColor: 'transparent',
        marginVertical: 5,
        marginBottom: 30,
        borderWidth: 1,
        //borderWidth: 0.5, // Set the border width
        borderColor: 'gray', // Set the border color
        borderRadius: 5, // Set the border radius (optional)
        shadowColor: 'blue', // Set the shadow color
        shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
        shadowOpacity: 0.5, // Set the shadow opacity
        shadowRadius: 4, // Set the shadow radius
        elevation: 4,
        minHeight:60,
    maxHeight:60,
      },
      date: {
        minWidth: '100%',
        minHeight: 60,
        maxHeight: 60,

        backgroundColor: 'transparent',
        marginVertical: 5,
        marginBottom: 30,
        borderWidth: 1,
        //borderWidth: 0.5, // Set the border width
        borderColor: 'gray', // Set the border color
        borderRadius: 5, // Set the border radius (optional)
        shadowColor: 'blue', // Set the shadow color
        shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
        shadowOpacity: 0.5, // Set the shadow opacity
        shadowRadius: 4, // Set the shadow radius
        elevation: 4
      },
      buttonsContainer: {
        flex: 1,
        //padding: 16,
        gap: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
        //alignItems: 'center',
        //justifyContent: 'space-evenly',
        height:60
    }
    });
    
    export default PaperForm;
    