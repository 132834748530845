import { BlurView } from "expo-blur";
import React from "react";
import { View, Text, ScrollView } from "react-native";
import { IconButton, MD3Colors, Tooltip } from "react-native-paper";
import { SingleSelectDropdown } from "../common/controls/Dropdown-filter";
import ButtonControl from "../common/controls/Button";
interface FilterIconProps {
  FilterVisible: boolean;
  setFilterVisible: (val: boolean) => void;
}
const FilterIcon: React.FC<FilterIconProps> = ({ FilterVisible, setFilterVisible }) => {
  return (
    <Tooltip title="Filter Options">
      <IconButton
        style={{}}
        icon="view-grid-outline"
        iconColor={'#004876'}
        size={20}
        onPress={() => setFilterVisible(!FilterVisible)}
      />
    </Tooltip>
  )
}
interface ExportIconProps {
  newData: any;
  handleExportClick: (val: any) => void;
}
const ExportIcon: React.FC<ExportIconProps> = ({ newData, handleExportClick }) => {
  return (
    <Tooltip title="Export to excel">
      <IconButton
        style={{}}
        icon="export-variant"
        iconColor={'#004876'}
        size={20}
        onPress={() => handleExportClick(newData)}
      />
    </Tooltip>
  )
}
interface FilterBoxProps {
  FilterVisible: any;
  setFilterVisible: any;
  screenWidth: number;
  CommonStyles: any;
  yearFilterOptions?: any;
  handleYearFilterSelect: any;
  selectedYear: any;
  monthFilterOptions?: any;
  handleMonthFilterSelect: any;
  selectedMonth: any;
  packageFilterOptions: any;
  handlePackageFilterSelect: any;
  selectedPackage: any;
  handleFilterReset: any;
  setSelectedMonth: any;
  setSelectedYear: any;
  setSelectedPackage: any;
  setNewData: any;
  updatedData: any;
}
const FilterBox: React.FC<FilterBoxProps> = ({
  setFilterVisible, FilterVisible, screenWidth, CommonStyles,
  yearFilterOptions, handleYearFilterSelect, selectedYear,
  monthFilterOptions, handleMonthFilterSelect, selectedMonth,
  packageFilterOptions, handlePackageFilterSelect, selectedPackage,
  handleFilterReset, setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData
}) => {
  return (
    <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
      <View>
        <Tooltip title="Close">
          <IconButton
            style={{ marginLeft: screenWidth - 60 }}
            icon="close-circle-outline"
            iconColor={MD3Colors.error0}
            size={20}
            onPress={() => setFilterVisible(!FilterVisible)}
          />
        </Tooltip>
      </View>

      <View style={[CommonStyles.container]} key={0}>
        {yearFilterOptions && <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
          <View style={{ width: '100%' }}><Text style={{ color: '#004876' }}>{'Filter by Year'}</Text></View>
          <SingleSelectDropdown
            options={yearFilterOptions}
            label={"Filter by Year"}
            onSelect={handleYearFilterSelect}
            selected={selectedYear}
          /></View>}
        {monthFilterOptions && <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
          <View style={{ width: '100%' }}><Text style={{ color: '#004876' }}>{'Filter by Month'}</Text></View>
          <SingleSelectDropdown
            options={monthFilterOptions}
            label={"Filter by Month"}
            onSelect={handleMonthFilterSelect}
            selected={selectedMonth}
          /></View>}
        <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
          <View style={{ width: '100%' }}><Text style={{ color: '#004876' }}>{'Filter by Package'}</Text></View>
          <SingleSelectDropdown
            options={packageFilterOptions}
            label={"Filter by Package"}
            onSelect={handlePackageFilterSelect}
            selected={selectedPackage}
          /></View>

      </View>
      <View style={[CommonStyles.container, { alignContent: 'center', alignSelf: 'center', flex: 1, flexDirection: 'row', gap: 10 }]}>
        <ButtonControl icon="arrow-left" mode="contained"
          onPress={() => { handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData); setFilterVisible(false) }}
          Title='Clear' disabled={false} />
        <ButtonControl icon="filter-check" mode="contained"
          onPress={() => { setFilterVisible(!FilterVisible) }}
          Title=' Ok ' disabled={false} />
      </View>
    </BlurView>
  )
}
const IconsContainer = () => {
  return (
    <View style={{ flexDirection: 'row' }}>

    </View>
  )
}

interface SelectedFiltersProps {
  selectedYear?: string;
  selectedMonth?: string;
  selectedPackage?: string;
  cardTitle?:string;
  number?:number;
}
const SelectedFilters: React.FC<SelectedFiltersProps> = ({ selectedYear, selectedMonth, selectedPackage, cardTitle, number }) => {
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <View style={{ flexDirection: 'row', flex: 1, gap: 20, alignSelf: 'center' }}>
        {selectedYear && <View><Text style={{ fontWeight: 'bold' }}>{'Selected Year: '}<Text style={{ fontWeight: 'normal' }}>{selectedYear}</Text></Text></View>}
        {selectedMonth && <View><Text style={{ fontWeight: 'bold' }}>{'Selected Month: '}<Text style={{ fontWeight: 'normal' }}>{selectedMonth}</Text></Text></View>}
        {selectedPackage && <View><Text style={{ fontWeight: 'bold' }}>{'Selected Package: '}<Text style={{ fontWeight: 'normal' }}>{selectedPackage}</Text></Text></View>}
        {/* {cardTitle && number && <View style={{justifyContent:"center", borderWidth:2, borderColor:'blue'}}><Text style={{ fontWeight: 'bold' }}>{cardTitle+ ': '+ Math.round(number)}</Text></View>} */}
      </View>
    </ScrollView>
  )
}


export { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters }