import * as React from 'react';
import { Platform } from 'react-native';
import { VictoryLabel, VictoryPie } from 'victory-native';

interface inputData {
  name: string;
  value: number;
  color: string;
}

interface Data {
  x: string;
  y: number;
}

interface prop {
  input: inputData[];
  donutRadius?: number;
}
const PieChart: React.FC<prop> = ({ input, donutRadius }) => {
  const [data, setData] = React.useState<Data[]>([]);
  const[colors,setColors]=React.useState<string[]>([]);


  React.useEffect(() => {
    if (input && input.length > 0) {
      const tempData = input.map(obj => { 
        return { x: obj.name, y: obj.value }
      });
      setData(tempData);

      const tempColors = input.map(obj => {
        return obj.color;
      });
      setColors(tempColors);
    }
    else {
      setData([]);
    }

  }, [input])

  return <>
    <VictoryPie
      //categories={{ x: ["Dogs", "Birds", "Cats","Cows"] }}
      // animate={{
      //     duration: 2000,animationWhitelist:["style", "data", "size"], easing: "bounce",
      //     onExit: {
      //         duration: 500,
      //         before: () => ({ opacity: 0.3, _y: 0 })
      //       },
      //       onEnter: {
      //         duration: 500,
      //         before: () => ({ opacity: 0.3, _y: 0 }),
      //         after: (datum) => ({ opacity: 1, _y: datum._y })
      //       }
      //   }}

      animate={{
        duration: 1000
      }}
      colorScale={colors}
      innerRadius={donutRadius ? donutRadius : 0}
      labels={({ datum }) => ` ${datum.y}`}
      //labelComponent={<VictoryLabel angle={45}/>}
      //padAngle={({ datum }) => 25}
      height={Platform.OS === 'web'?400:280}
      data={data}
    />
  </>;
}

export default PieChart;
