import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox, Dimensions, Modal } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import BarChartsComp from '../common/charts/barChart-builder';
import ToastMessage from '../common/controls/Toast';
import ButtonControl from '../common/controls/Button';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters } from './ReUsableComps';
import { BlurView } from 'expo-blur';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import formCustomStyles from '../common/forms/formStyles';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import ConsolLogCountCard from './ConsolLogCountCard';
import GeoMap from '../common/controls/GeoMap';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ConsolObservationLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [observationLogData, setLogData] = useState<null | any>(null);
  const [observationLogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return observationLogData && observationLogColumns ? <CreateLogCharts setObservationLogData={setLogData} observationLogData={observationLogData} observationLogColumns={observationLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : null;
}
interface CreateLogChartsProps {
  observationLogData: any;
  observationLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setObservationLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ setObservationLogData, observationLogData, observationLogColumns, siteId, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();

  const ColumnsWithIDColumn = observationLogColumns && [
    ...observationLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const sortedData = [...observationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const DueDate = item.DueDate && item.DueDate != null ? returnDate(item.DueDate) : null;
    const DateClosedOut = item.DateClosedOut && item.DateClosedOut != null ? returnDate(item.DateClosedOut) : null;
    const Status = checkStatus(item.ClosedOut)
    const ReferenceNumber = item.ReferenceNumber === null ? item.ID : item.ReferenceNumber + "-" + item.ID;
    return { ...item, Period, key, Date, ReferenceNumber, DueDate, DateClosedOut };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  const recordsWithCoordinates = updatedData && updatedData.filter(record => {
    
  
    let loc = record.Location && record.Location;
    if(loc && loc.includes('coords'))
    {
      const location = JSON.parse(record.Location);
      return location && location.coords && location.coords.latitude && location.coords.longitude ;
    }
  });
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkStatus(div: string) {
    switch (div) {
      case "Yes": return "Closed";
      case "No": return "Open";
      case "Escalated": return "Escalated";
      default: return null
    }
  }
  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];

  /**
   * Creating Line Chart by Status
   */
  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const statusValues = [...new Set(newData.map((item: any) => item.Status))].filter(type => type !== null);
  const statusData: any = {};
  statusValues.forEach((status) => {
    statusData[status] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Status === status)
        .length;
      statusData[status].push(filteredData);
    });
  });

  const datasets = Object.entries(statusData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets,
  };


  const legendData1 = [...statusValues].map((type: any, index: any) => ({
    name: type,
  }));

  /**
   * Creating Line Chart by Type
   */

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);
  const oTypeValues = [...new Set(newData.map((item: any) => item.ObservationType))].filter(type => type !== null);
  const typeData: any = {};
  oTypeValues.forEach((type) => {
    typeData[type] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.ObservationType === type)
        .length;
      typeData[type].push(filteredData);
    });
  });

  const typeDatasets = Object.entries(typeData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData2 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets: typeDatasets,
  };
  const legendData2 = [...oTypeValues].map((type: any, index: any) => ({
    name: type,
  }));

  /**
   * Creating Line Chart by Catgory
   */
  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const CategoryValues = [...new Set(newData.map((item: any) => item.Category))].filter(type => type !== null);
  const CategoryData: any = {};
  CategoryValues.forEach((category) => {
    CategoryData[category] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Category === category)
        .length;
      CategoryData[category].push(filteredData);
    });
  });

  const CategoryDatasets = Object.entries(CategoryData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData3 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets: CategoryDatasets,
  };
  const legendData3 = [...CategoryValues].map((type: any, index: any) => ({
    name: type,
  }));
  /**
   * Pie Chart by Status
   */
  const pieDataStatus: any = {};
  newData.forEach((item: any) => {
    const Key = item.Status;
    if (pieDataStatus[Key]) {
      pieDataStatus[Key] += 1;
    } else {
      pieDataStatus[Key] = 1;
    }
  });
  const colorScaleStatus = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const StatusPieData = Object.keys(pieDataStatus).map((key, index) => ({
    name: key,
    value: pieDataStatus[key],
    color: colorType5(index.toString()),//colorScaleStatus(pieDataStatus[key] / Math.max(...Object.values(pieDataStatus) as number[])),
  }));

  /**
   * Pie Chart by Type
   */
  const pieDataObservationType: any = {};
  newData.forEach((item: any) => {
    const Key = item.ObservationType;
    if (pieDataObservationType[Key]) {
      pieDataObservationType[Key] += 1;
    } else {
      pieDataObservationType[Key] = 1;
    }
  });
  const colorScaleObservationType = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const TypePieData = Object.keys(pieDataObservationType).map((key, index) => ({
    name: key,
    value: pieDataObservationType[key],
    color: colorType3(index.toString()),//colorScaleObservationType(pieDataObservationType[key] / Math.max(...Object.values(pieDataObservationType) as number[])),
  }));

  /**
   * Pie Chart by Catgory
   */
  const pieDataCatgory: any = {};
  newData.forEach((item: any) => {
    const Key = item.Catgory;
    if (pieDataCatgory[Key]) {
      pieDataCatgory[Key] += 1;
    } else {
      pieDataCatgory[Key] = 1;
    }
  });
  const colorScaleCatgory = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const CatgoryPieData = Object.keys(pieDataCatgory).map((key, index) => ({
    name: key,
    value: pieDataCatgory[key],
    color: colorType5(index.toString()),//colorScaleCatgory(pieDataCatgory[key] / Math.max(...Object.values(pieDataCatgory) as number[])),
  }));
  /**
   * End of Charts
   */


  /**
   * Custom Table By Observation Type
   */

  const ObservationTypeLabels = observationLogColumns.find((col: any) => col.columnName === 'ObservationType')?.choiceChoices;
  const ObservationTypeTable = ObservationTypeLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable1 = newData.filter(item => item.ObservationType != '' || item.ObservationType != null).reduce((result, item) => {
    const { Period, ObservationType } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      ObservationTypeTable.forEach((oType: string) => {
        result[Period][oType] = 0; // Initialize with 0 for each ObservationType
      });
    }
    result[Period][ObservationType] += 1
    return result;
  }, {});
  interface GroupedDataItem2 {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable1Array: GroupedDataItem2[] = Object.values(groupedDataTable1);
  const fData = groupedDataTable1Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });


  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalOpen = filteredItems.filter(item => item.Status === "Open");
    const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
    const sumOpen = TotalOpen.length;
    const sumClosed = TotalClosed.length;
    const filteredItems1 = fData.filter(item => item.Period === Period);

    const result = [
      ["Period", filteredItems1[0]?.Period],
      ["Total Open", sumOpen],
      ["Total Closed", sumClosed],
    ];

    filteredItems1.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => {
    return {
      ...values,
    };
  });


  const combinedData1: { [key: string]: { [key: string]: number } } = {};

  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });

  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));

  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /**
   * Create Table 2 By Observation Category
   * 
   */

  const CategoryLabels = observationLogColumns.find((col: any) => col.columnName === 'Category')?.choiceChoices;
  const CategoryTable = CategoryLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable2 = newData.filter(item => item.Category != '' || item.Category != null).reduce((result, item) => {
    const { Period, Category } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      CategoryTable.forEach((category: string) => {
        result[Period][category] = 0; // Initialize with 0 for each Category
      });
    }
    result[Period][Category] += 1
    return result;
  }, {});
  interface GroupedDataItem {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable2Array: GroupedDataItem[] = Object.values(groupedDataTable2);
  const fData2 = groupedDataTable2Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });

  const filteredDataByPeriod2 = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalOpen = filteredItems.filter(item => item.Status === "Open");
    const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
    const sumOpen = TotalOpen.length;
    const sumClosed = TotalClosed.length;
    const filteredItems2 = fData2.filter(item => item.Period === Period);

    const result = [
      ["Period", filteredItems2[0]?.Period],
      ["Total Open", sumOpen],
      ["Total Closed", sumClosed],
    ];

    filteredItems2.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  const transformedDataTable2 = Object.values(filteredDataByPeriod2).map((values: any) => {
    return {
      ...values,
    };
  });


  const combinedData2: { [key: string]: { [key: string]: number } } = {};

  filteredDataByPeriod2.forEach(item => {
    const { Period, ...rest } = item;
    combinedData2[Period] = { ...combinedData2[Period], ...rest };
  });

  const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
    Period,
    ...values
  }));

  const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];




  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = newData.length;
  return (
    <View style={logStyles.logRoot}>

      <View style={CommonStyles.ribbonIconContainer}>
        <View style={CommonStyles.IconContainer}>
          <FilterIcon setFilterVisible={setFilterVisible} FilterVisible={FilterVisible} />
        </View>
        <View style={CommonStyles.IconContainer}>
          {
            EnableExport() === true ?
              <ExportIcon handleExportClick={handleExportClick} newData={newData} />
              : null
          }
        </View>

        <SelectedFilters selectedMonth={selectedMonth} selectedPackage={selectedPackage} selectedYear={selectedYear} />

      </View>
      <View style={[{justifyContent: 'center', alignItems:'center'}]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Observations'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={FilterVisible}
        onRequestClose={() => {
          setFilterVisible(!FilterVisible);
        }}>
        <View style={formCustomStyles.centeredView}>
          {/* <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
            <View>
              <Tooltip title="Close">
                <IconButton
                  style={{ marginLeft: screenWidth - 60 }} 
                  icon="close-circle-outline"
                  iconColor={MD3Colors.error0}
                  size={20}
                  onPress={() => setFilterVisible(!FilterVisible)}
                />
              </Tooltip>
            </View>

            <View style={[CommonStyles.container]} key={0}>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <View style={{width:'100%'}}><Text style={{color:'#FFFFFF'}}>{'Filter by Year'}</Text></View>
                <SingleSelectDropdown
                  options={yearFilterOptions}
                  label={"Filter by Year"}
                  onSelect={handleYearFilterSelect}
                  selected={selectedYear}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <View style={{width:'100%'}}><Text style={{color:'#FFFFFF'}}>{'Filter by Month'}</Text></View>
                <SingleSelectDropdown
                  options={monthFilterOptions}
                  label={"Filter by Month"}
                  onSelect={handleMonthFilterSelect}
                  selected={selectedMonth}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <View style={{width:'100%'}}><Text style={{color:'#FFFFFF'}}>{'Filter by Package'}</Text></View>
                <SingleSelectDropdown
                  options={packageFilterOptions}
                  label={"Filter by Package"}
                  onSelect={handlePackageFilterSelect}
                  selected={selectedPackage}
                /></View>
              
            </View>
            <View style={[ {alignContent:'center', alignSelf:'center', flex:1, width:150}]}>
                <ButtonControl icon="arrow-left" mode="contained"
                  onPress={() => {handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData); setFilterVisible(false)}}
                  Title='Close' disabled={false} />
              </View>
          </BlurView> */}
          <FilterBox
            FilterVisible={FilterVisible}
            setFilterVisible={setFilterVisible}
            screenWidth={screenWidth}
            CommonStyles={CommonStyles}
            yearFilterOptions={yearFilterOptions}
            handleYearFilterSelect={handleYearFilterSelect}
            selectedYear={selectedYear}
            monthFilterOptions={monthFilterOptions}
            handleMonthFilterSelect={handleMonthFilterSelect}
            selectedMonth={selectedMonth}
            packageFilterOptions={packageFilterOptions}
            handlePackageFilterSelect={handlePackageFilterSelect}
            selectedPackage={selectedPackage}
            handleFilterReset={handleFilterReset}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            setSelectedPackage={setSelectedPackage}
            setNewData={setNewData}
            updatedData={updatedData}
          />
        </View>
      </Modal>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>


      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
            <View style={CommonStyles.container}>
                    <View style={[CommonStyles.cardTwo,{height:350}]}>
                      {recordsWithCoordinates &&  <GeoMap data={recordsWithCoordinates} />}
                    </View>
                  </View>
                <View>
                  
                  <View style={CommonStyles.container}>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={StatusPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Status"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Status"} /></View>
                  
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={TypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Type"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData2} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Type"} /></View>
                  
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={CatgoryPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Category"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData3} data={lineData3} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Category"} /></View>
                    
                  </View>
                </View>
            </ScrollView>
          </TabScreen>

          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                <View style={CommonStyles.container}>
                  <View style={CommonStyles.cardTwo}>
                    {
                      newData &&
                      newData.length > 0 &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={true}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};


