import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox, Platform } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import { schemePaired, schemeAccent, schemeSet2 } from 'd3-scale-chromatic';
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import BarChartsComp from '../common/charts/barChart-builder';
import { colorType3, colorType5, logStyles } from './LogStyles';
import { Loader } from '../../Common/Loader';
import LogFilter from './LogFilterDropdown';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import ChartStyles from '../common/charts/chartStyles';
import LogCountCard from './LogCountCard';
import GeoMapApp from '../common/controls/GeoMap';
import GeoMap from '../common/controls/GeoMap';
import GeoHeatMap from '../common/controls/HeatMap';
interface Props {
  siteId: string;
  logTitle: string;
  Permissions?: string;
}
export default function ObservationLog(props: Props) {
  //LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [observationLogData, setObservationLogData] = useState<null | any>(null);
  const [observationLogColumns, setObservationLogColumns] = useState<null | any>(null);
  const [onlyForm, setonlyForm] = useState<boolean>(false);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  let itemID = undefined;
  const url = Platform.OS === 'web' ? new URLSearchParams(document.location.search) : null;
  itemID = url && url.get('ID');
  if (url && itemID) {
    if (parseInt(itemID) && parseInt(itemID) > 0) {
      useEffect(() => {
        setonlyForm(true);
      }, [itemID])
    }
  }
  useEffect(() => {
    async function fetchData() {
      try {


        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        //debugger;
        setObservationLogData(json.items);
        setObservationLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return observationLogData && observationLogColumns ? <CreateLogCharts itemID={itemID} FormOnly={onlyForm} Permissions={props.Permissions} setObservationLogData={setObservationLogData} observationLogData={observationLogData} observationLogColumns={observationLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateLogChartsProps {
  observationLogData: any;
  observationLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setObservationLogData: any;
  Permissions?: string;
  FormOnly: boolean;
  itemID: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ FormOnly, itemID, Permissions, setObservationLogData, observationLogData, observationLogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();

  const ColumnsWithIDColumn = observationLogColumns && [
    ...observationLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const sortedData = [...observationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const DueDate = item.DueDate && item.DueDate != null ? returnDate(item.DueDate) : null;
    const DateClosedOut = item.DateClosedOut && item.DateClosedOut != null ? returnDate(item.DateClosedOut) : null;
    const Status = checkStatus(item.ClosedOut)
    const ReferenceNumber = item.ReferenceNumber === null ? item.ID : item.ReferenceNumber + "-" + item.ID;
    return { ...item, Period, key, Date, ReferenceNumber, DueDate, DateClosedOut };
  });

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [onlyForm, setonlyForm] = useState<boolean>(FormOnly);
  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  const recordsWithCoordinates = updatedData && updatedData.filter(record => {
    
  
    let loc = record.Location && record.Location;
    if(loc && loc.includes('coords'))
    {
      const location = JSON.parse(record.Location);
      return location && location.coords && location.coords.latitude && location.coords.longitude ;
    }
  });
  
  //console.log(recordsWithCoordinates);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }

  function checkStatus(div: string) {
    switch (div) {
      case "Yes": return "Closed";
      case "No": return "Open";
      case "Escalated": return "Escalated";
      default: return null
    }
  }
  function handleYearFilterSelect(val: string) {

    let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const DueDate = result.items[0] && result.items[0].DueDate && returnDate(result.items[0].DueDate)
        const DateClosedOut = result.items[0] && result.items[0].DateClosedOut && returnDate(result.items[0].DateClosedOut)
        const newItem = { ...result.items[0], Date, DueDate, DateClosedOut }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];

  /**
   * Creating Line Chart by Status
   */
  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const statusValues = [...new Set(newData.map((item: any) => item.Status))].filter(type => type !== null);
  const statusData: any = {};
  statusValues.forEach((status) => {
    statusData[status] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Status === status)
        .length;
      statusData[status].push(filteredData);
    });
  });
  const LineColorScaleStatus = scaleOrdinal(schemePaired);
  const datasets = Object.entries(statusData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 4,
    color: (opacity = 0) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
    //color: LineColorScaleStatus(index),
  }));
  //const sortedData = [...newData].sort((a:any, b:any) => new Date(b.Date) - new Date(a.Date));


  const lineData1 = {
    labels: [...new Set(newData.filter(type => type.Period !== null).map((item: any) => item.Period))],
    datasets,
    legend: statusValues,
  };

  const legendData1 = [...statusValues].map((type: any, index: any) => ({
    name: type,
  }));


  /**
   * Creating Line Chart by Type
   */

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);
  //const oTypeValues = [...new Set(newData.map((item: any) => item.ObservationType))]
  const oTypeValues = [...new Set(newData.map((item: any) => item.ObservationType))].filter(type => type !== null);
  const typeData: any = {};
  oTypeValues.forEach((type) => {
    typeData[type] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.ObservationType === type)
        .length;
      typeData[type].push(filteredData);
    });
  });
  const LineColorScaleType = scaleOrdinal(schemePaired);
  const typeDatasets = Object.entries(typeData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 4,
    color: (opacity = 0) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
    //color:LineColorScaleType(key)
  }));

  const lineData2 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets: typeDatasets,
    legend: oTypeValues,
  };
  const legendData2 = [...oTypeValues].map((type: any, index: any) => ({
    name: type,
  }));

  /**
   * Creating Line Chart by Catgory
   */
  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const CategoryValues = [...new Set(newData.map((item: any) => item.Category))].filter(type => type !== null);
  const CategoryData: any = {};
  CategoryValues.forEach((category) => {
    CategoryData[category] = [];
    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Category === category)
        .length;
      CategoryData[category].push(filteredData);
    });
  });
  const LineColorScaleCategory = scaleOrdinal(schemePaired);
  const CategoryDatasets = Object.entries(CategoryData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 4,
    //color: LineColorScaleCategory(key)//
    color: (opacity = 0) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData3 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets: CategoryDatasets,
    legend: CategoryValues,
  };
  const legendData3 = [...CategoryValues].map((type: any, index: any) => ({
    name: type,
  }));
  /**
   * Pie Chart by Status
   */
  const pieDataStatus: any = {};
  newData.forEach((item: any) => {
    const Key = item.Status;
    if (pieDataStatus[Key]) {
      pieDataStatus[Key] += 1;
    } else {
      pieDataStatus[Key] = 1;
    }
  });
  const colorScaleStatus = scaleOrdinal(schemePaired);
  

  //const colorScaleStatus = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const StatusPieData = Object.keys(pieDataStatus).map(key => ({
    name: key,
    value: pieDataStatus[key],
    //color: colorScaleStatus(pieDataStatus[key] / Math.max(...Object.values(pieDataStatus) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart by Type
   */
  const pieDataObservationType: any = {};
  newData.forEach((item: any) => {
    const Key = item.ObservationType;
    if (pieDataObservationType[Key]) {
      pieDataObservationType[Key] += 1;
    } else {
      pieDataObservationType[Key] = 1;
    }
  });
  const colorScaleObservationType = scaleOrdinal(schemeAccent);
  //const colorScaleObservationType = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const TypePieData = Object.keys(pieDataObservationType).map(key => ({
    name: key,
    value: pieDataObservationType[key],
    //color: colorScaleObservationType(pieDataObservationType[key] / Math.max(...Object.values(pieDataObservationType) as number[])),
    color: colorType3(key),
  }));

  /**
   * Pie Chart by Catgory
   */
  const pieDataCatgory: any = {};
  newData.forEach((item: any) => {
    const Key = item.Category;
    if (pieDataCatgory[Key]) {
      pieDataCatgory[Key] += 1;
    } else {
      pieDataCatgory[Key] = 1;
    }
  });
  const colorScaleCatgory = scaleOrdinal(schemeSet2);
  //const colorScaleCatgory = scaleSequential().domain([0, 1]).interpolator(interpolateBrBG);
  const CatgoryPieData = Object.keys(pieDataCatgory).map(key => ({
    name: key,
    value: pieDataCatgory[key],
    //color: colorScaleCatgory(pieDataCatgory[key] / Math.max(...Object.values(pieDataCatgory) as number[])),
    color: colorType5(key),
  }));
  /**
   * End of Charts
   */


  /**
   * Custom Table By Observation Type
   */
  //"Observation#;#Findings#;#Good Practice/ Strong Point#;#Incident#;#Non Compliance#;#Non Conformance#;#Other#;#Public Complaint";
  const ObservationTypeLabels = observationLogColumns.find((col: any) => col.columnName === 'ObservationType')?.choiceChoices;
  const ObservationTypeTable = ObservationTypeLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable1 = newData.filter(item => item.ObservationType != '' || item.ObservationType != null).reduce((result, item) => {
    const { Period, ObservationType } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      ObservationTypeTable.forEach((oType: string) => {
        result[Period][oType] = 0; // Initialize with 0 for each ObservationType
      });
    }
    result[Period][ObservationType] += 1
    return result;
  }, {});
  interface GroupedDataItem2 {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable1Array: GroupedDataItem2[] = Object.values(groupedDataTable1);
  const fData = groupedDataTable1Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });


  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalOpen = filteredItems.filter(item => item.Status !== "Closed");
    const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
    const sumOpen = TotalOpen.length;
    const sumClosed = TotalClosed.length;
    const filteredItems1 = fData.filter(item => item.Period === Period);

    const result = [
      ["Period", filteredItems1[0]?.Period],
      ["Total Open", sumOpen],
      ["Total Closed", sumClosed],
    ];

    filteredItems1.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => {
    return {
      ...values,
    };
  });


  const combinedData1: { [key: string]: { [key: string]: number } } = {};

  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });

  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));

  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /**
   * Create Table 2 By Observation Category
   * 
   */

  const CategoryLabels = observationLogColumns.find((col: any) => col.columnName === 'Category')?.choiceChoices;
  const CategoryTable = CategoryLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable2 = newData.filter(item => item.Category != '' || item.Category != null).reduce((result, item) => {
    const { Period, Category } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      CategoryTable.forEach((category: string) => {
        result[Period][category] = 0; // Initialize with 0 for each Category
      });
    }
    result[Period][Category] += 1
    return result;
  }, {});
  interface GroupedDataItem {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable2Array: GroupedDataItem[] = Object.values(groupedDataTable2);
  const fData2 = groupedDataTable2Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });

  const filteredDataByPeriod2 = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalOpen = filteredItems.filter(item => item.Status !== "Closed");
    const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
    const sumOpen = TotalOpen.length;
    const sumClosed = TotalClosed.length;
    const filteredItems2 = fData2.filter(item => item.Period === Period);

    const result = [
      ["Period", filteredItems2[0]?.Period],
      ["Total Open", sumOpen],
      ["Total Closed", sumClosed],
    ];

    filteredItems2.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  const transformedDataTable2 = Object.values(filteredDataByPeriod2).map((values: any) => {
    return {
      ...values,
    };
  });


  const combinedData2: { [key: string]: { [key: string]: number } } = {};

  filteredDataByPeriod2.forEach(item => {
    const { Period, ...rest } = item;
    combinedData2[Period] = { ...combinedData2[Period], ...rest };
  });

  const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
    Period,
    ...values
  }));

  const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];



  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a: any, b: any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]
  const chartVisibility = (val: boolean) => {
    setonlyForm(val);
  }
  // const url = Platform.OS === 'web' ? new URLSearchParams(document.location.search) : null;
  // const itemID = url && url.get('ID');
  // if (parseInt(itemID) && parseInt(itemID) > 0) {
  //   useEffect(() => {
  //     chartVisibility(true);
  //   }, [itemID])
  // }


  return (
    <View style={logStyles.logRoot}>
      <TabsProvider defaultIndex={onlyForm === false ? 0 : 2}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={logStyles.scrollViewContentContainer}>
              {//onlyForm === true ? null :
                <View>
                  <View>
                    <View style={logStyles.container} key={0}>
                      <View style={[logStyles.cardTwo, { height: 350 }]}>
                        {recordsWithCoordinates && <GeoMap data={recordsWithCoordinates} />}
                      </View>
                    </View>
                  </View>
                  <View>
                    <View style={[logStyles.container]} key={1}>
                      <LogCountCard cardTitle={'Total Observations'} number={newData.length} />
                      <View style={[logStyles.card, { justifyContent: 'center' }]}>
                        <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                      </View>
                    </View>
                  </View>
                  <View>
                    <View style={logStyles.container}>
                      <View style={[logStyles.card]}><PieChartComp data={StatusPieData} colorPallate={interpolateBlues} subTitle={'(cumulative)'} ChartTitle={"Observations / Status"} /></View>
                      <View style={[logStyles.card]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(monthly)'} ChartTitle={"Observations / Status"} /></View>

                      <View style={[logStyles.card]}><PieChartComp data={TypePieData} colorPallate={interpolateBlues} subTitle={'(cumulative)'} ChartTitle={"Observations / Type"} /></View>
                      <View style={[logStyles.card]}><LineChartScreen legend={legendData2} data={lineData2} label={uniquePeriod} subTitle={'(monthly)'} ChartTitle={"Observations / Type"} /></View>

                      <View style={[logStyles.card]}><PieChartComp data={CatgoryPieData} colorPallate={interpolateBlues} subTitle={'(cumulative)'} ChartTitle={"Observations / Category"} /></View>
                      <View style={[logStyles.card]}><LineChartScreen legend={legendData3} data={lineData3} label={uniquePeriod} subTitle={'(monthly)'} ChartTitle={"Observations / Category"} /></View>
                    </View>
                  </View>
                  
                </View>
              }
            </ScrollView>
          </TabScreen>
          <TabScreen label="Tables" icon="table" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              {//onlyForm === true ? null :
                <View>
                  <View style={[logStyles.container]} key={0}>
                    <View style={logStyles.cardTwoFilter}>
                      <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                    </View>
                  </View>
                  <View>
                    <View style={logStyles.container}>
                      <View style={logStyles.cardTwo}>
                        {mergedData1 && columnKeys1 && mergedData1.length > 0 ? (
                          <CustomDT
                            data={mergedData1}
                            columns={columnKeys1}
                            siteId={siteId}
                            logTitle={logTitle}
                          />
                        ) : null
                        }
                      </View>
                    </View>
                    <View style={logStyles.container}>
                      <View style={logStyles.cardTwo}>
                        {mergedData2 && columnKeys2 && mergedData2.length > 0 ? (
                          <CustomDT
                            data={mergedData2}
                            columns={columnKeys2}
                            siteId={siteId}
                            logTitle={logTitle}
                          />
                        ) : null
                        }
                      </View>
                    </View>
                  </View>
                </View>
              }
            </ScrollView>
          </TabScreen>
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                {itemID ? null: <View style={[logStyles.container]} key={0}>
                  <View style={logStyles.cardTwoFilter}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View>}
                <View>
                  <View style={logStyles.container}>
                    <View style={logStyles.cardTwo}>
                      {
                        setNewData &&
                        newData &&
                        uniqueYearsArray &&
                        ColumnsWithIDColumn && (
                          <PaperTableDynamic
                            data={newData}
                            columns={ColumnsWithIDColumn}
                            siteId={siteId}
                            logTitle={logTitle}
                            updateData={handleDataUpdate}
                            isConsolidatedTable={false}
                            Permissions={Permissions}
                            QueryStringID={itemID && itemID}
                            chartVisibility={chartVisibility}
                          />
                        )
                      }
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};


// const logStyles = StyleSheet.create({
//   title: {
//     fontSize: 18,
//     fontWeight: 'bold',
//     borderRadius: 16,
//   },
//   container: {
//     flex: 1,
//     //padding: 16,
//     gap: 10,
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     alignItems: 'center',
//     justifyContent: 'space-evenly'
//   },
//   card: {
//     maxWidth: 500,
//     minWidth: 300,
//     marginVertical: 8,
//     minHeight: 100,
//     width: '100%',
//     //height:'100%'
//   },
// });