import React, { useState } from "react"
import { StyleSheet, View, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import ConsolEnergyLog from "./ConsolidatedLogs/ConsolEnergyLog";
import ConsolWaterLog from "./ConsolidatedLogs/ConsolWaterLog";
import ConsolNoiseLog from "./ConsolidatedLogs/ConsolNoiseLog";
import ConsolImportedSoilLog from "./ConsolidatedLogs/ConsolImportedSoilLog";
import ConsolHazardousMaterial from "./ConsolidatedLogs/ConsolHazardousMaterial";
import ConsolRecycledMaterial from "./ConsolidatedLogs/ConsolRecycledMaterial";
import ConsolRegionalMaterial from "./ConsolidatedLogs/ConsolRegionalMaterial";
import ConsolVibrationMonitoring from "./ConsolidatedLogs/ConsolVibrationMonitoring";
import ConsolPM10Monitoring from "./ConsolidatedLogs/ConsolPM10Monitoring";
import ConsolWasteWaterLog from "./ConsolidatedLogs/ConsolWasteWaterLog";
import ConsolWasteLog from "./ConsolidatedLogs/ConsolWasteLog";
import ConsolSpoilLog from "./ConsolidatedLogs/ConsolSpoilLog";
import ConsolTrainingLog from "./ConsolidatedLogs/ConsolTrainingLog";
import ConsolObservationLog from "./ConsolidatedLogs/ConsolObservationLog";
import MonthlyWasteLog from "./LogControllers/MonthlyWasteLog";
interface Props {
    logTitle: string;
    parentId: string;
}
const ReportProvider: React.FC<Props> = (props) => {
    //const [containerstyles, setcontainerstyles] = useState(Platform.OS ==="web" ? styles.mobilecontainer : styles.pccontainer );
    switch (props.logTitle) {
        case 'Noise Monitoring Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolNoiseLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Energy Log':
            return (
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolEnergyLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
            )
        case 'Water Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolWaterLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Imported Soil Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolImportedSoilLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Hazardous Material Storage Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolHazardousMaterial siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Recycled Materials Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolRecycledMaterial siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Regional Materials Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolRegionalMaterial siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Vibration Monitoring Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolVibrationMonitoring siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'PM10 Monitoring Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolPM10Monitoring siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Wastewater Tracking Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolWasteWaterLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Waste Tracking Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolWasteLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Spoil Tracking Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolSpoilLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Training Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolTrainingLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Environmental Issues Observations':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <ConsolObservationLog siteId={props.parentId} logTitle={props.logTitle} />
                            </View>
                        </View>
                    
            )
        case 'Monthly Waste Log':
            return (
                
                        <View style={styles.container}>
                            <View style={styles.logBackground} >
                                <MonthlyWasteLog siteId={props.parentId} logTitle={'Waste Tracking Log'} />
                            </View>
                        </View>
                    
            )
        default:
            return (
                null
            )
    }

}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // padding: 16,
        // gap: 20,
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        // alignItems: 'center',
        // justifyContent: 'space-evenly',
        // minHeight: 650,
        //backgroundColor: 'aliceblue',
    },
    logBackground: {
        //backgroundColor: 'rgba(255, 255, 255, 1)', 
        // flex: 1
    },
    // pccontainer: {
    //     marginTop: 8,
    //     backgroundColor: 'aliceblue',
    //     paddingLeft: '10%',
    //     paddingRight: '10%',
    //     height: 'auto',
    // },
    // mobilecontainer: {
    //     marginTop: 8,
    //     backgroundColor: 'aliceblue',
    //     paddingLeft: '10%',
    //     paddingRight: '10%',
    //     height: 'auto',
    // },
});
export default ReportProvider;