

async function getAssetData(imageFileName: string, accessToken: void, clientAPIURL: string) {
  try {
    const finalUri = clientAPIURL + "api/getAsset/" + imageFileName;
    const response = await fetch(finalUri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'cache':'force-cache'
      },
    });
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

async function triggerWorkflow(path: string, itemID: number, status: string, packageName: string, siteId: string, listTitle: string, api: string, accessToken: void) {
  const data = {
    "listPath": path,//"greenport/dgda/ss01/TrainingObservations/EnvironmentalIssuesObservations",
    "itemID": itemID,
    "status": status,
    "packageName": packageName,
    "siteId": siteId,
    "listTitle": listTitle
  }
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(api + "api/triggerWorkflow", requestOptions);
    return result;
  } catch (error) {
    console.error(error);
  }

}

async function getAllItems(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string) {

  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  try {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const response = await fetch(finalUri, requestOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
async function createItem(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string, data: Array<any>) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(finalUri, requestOptions);
    const response = await result.json();
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function upload(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string, data: any) {

  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(finalUri, requestOptions);
    const response = await result.json();
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getItemById(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string, itemId: number) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  try {
    const url = finalUri + "/" + itemId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
async function updateItem(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string, itemId: number, data: Array<any>) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  const url = finalUri + "/" + itemId
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(data)
    };
    const result = await fetch(url, requestOptions);
    return result;
  } catch (error) {
    console.error(error);
  }
}
async function deleteItem(logTitle: string, accessToken: void, clientAPIURL: string, siteId: string, itemId: number) {
  const finalUri = clientAPIURL + "api/sites/" + siteId + "/lists/" + logTitle + "/items"
  //const finalUri = baseAPI.replace("{siteId}", siteId).replace("{logTitle}", logTitle);
  const url = finalUri + "/" + itemId
  try {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` }
    };
    const result = await fetch(url, requestOptions);
    return result;
  } catch (error) {
    console.error(error);
  }
}

export { getAllItems, createItem, deleteItem, updateItem, getItemById, upload, triggerWorkflow,getAssetData };
