import React, { useState,useEffect } from 'react';
import { ScrollView, View, Text} from 'react-native';
import { DataTable } from 'react-native-paper';
import ControlStyles from './ControlStyles';
interface Props {
  data: Array<any>;
  columns: Array<any>;
  siteId:string;
  logTitle:string;
}

const CustomDT : React.FC<Props> =  (props) => {
  const optionsPerPage: number[] = [10, 20, 50];
  const data = props.data;
  const columns = props.columns;
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(optionsPerPage[1]);
  return(
<View>
      <View style={ControlStyles.tableContainer}>
        <DataTable>
          <ScrollView horizontal contentContainerStyle={{ flexDirection: 'column' }}>
            <DataTable.Header style={ControlStyles.dtHeader}>
              {columns.map((column, index) => (
                <DataTable.Title key={index} numberOfLines={1} style={[ControlStyles.dtTitle, {width:column.Title? column.Title.length*10:column.ColumnName.length*10}]}>
                  <Text style={ControlStyles.dtHeaderText}>{column.Title? column.Title:column.ColumnName}</Text></DataTable.Title>
              ))}
            </DataTable.Header>
            {data.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((row, index) => (
              <DataTable.Row key={index} style={{backgroundColor: index % 2 === 0 ? 'white': 'lightgray'}}>
                {columns.map((column, index) => (
                  <DataTable.Cell key={index} style={[ControlStyles.dtCell, {width:column.Title? column.Title.length*10:column.ColumnName.length*10}]}>{row[column.ColumnName]}</DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </ScrollView>
          <DataTable.Pagination
            numberOfItemsPerPageList={optionsPerPage}
            numberOfItemsPerPage={itemsPerPage}
            page={page}
            numberOfPages={Math.ceil(data.length / itemsPerPage)}
            onPageChange={(page) => setPage(page)}
            onItemsPerPageChange={setItemsPerPage}
            showFastPaginationControls
            selectPageDropdownLabel={'Rows per page'}
            label={`${page * itemsPerPage + 1}-${Math.min(
              (page + 1) * itemsPerPage,
              data.length
            )} of ${data.length}`}
            style={{alignSelf:'flex-start'}}
          />
        </DataTable>
      </View>
        </View>
  );

}

  export default CustomDT;