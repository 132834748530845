import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox, Dimensions, Modal } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';

import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import ToastMessage from '../common/controls/Toast';
import ButtonControl from '../common/controls/Button';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters } from './ReUsableComps';
import { BlurView } from 'expo-blur';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import formCustomStyles from '../common/forms/formStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ConsolWasteWaterLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [wasteWaterLogData, setLogData] = useState<null | any>(null);
  const [wasteWaterLogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return wasteWaterLogData && wasteWaterLogColumns ? <CreateLogCharts setWasteWaterLogData={setLogData} wasteWaterLogData={wasteWaterLogData} wasteWaterLogColumns={wasteWaterLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : null;
}
interface CreateLogChartsProps {
  wasteWaterLogData: any;
  wasteWaterLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setWasteWaterLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ setWasteWaterLogData, wasteWaterLogData, wasteWaterLogColumns, siteId, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = wasteWaterLogColumns && [
    ...wasteWaterLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const sortedData = [...wasteWaterLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, key, Date };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const uniqueDates = [...new Set(newData.map((item) => item.Date))];
  const filteredData = newData.filter(item => item.Quantitym3 !== null && item.Quantitym3 !== '');


  const uniqueDisposalMethods = [...new Set(filteredData.map(item => item.DisposalMethod))].filter(type => type !== null);
  const lineChartData: any = {};
  const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  };
  uniqueDisposalMethods.forEach((method) => {
    lineChartData[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueDisposalMethods.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.DisposalMethod === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.Quantitym3) ? acc + item.Quantitym3 : acc, 0);
      lineChartData[method].push(sum);
    });
  });
  const colorScaleLine = scaleOrdinal(schemeCategory10);
  const datasets = Object.entries(lineChartData).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineData = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasets
  };
  const legendData2 = [...uniqueDisposalMethods].map((type: any, index: any) => ({
    name: type,
  }));


  const uniqueWasteWaterType = [...new Set(filteredData.map(item => item.WastewaterType))].filter(type => type !== null);
  const lineChartDataByType: any = {};
  uniqueWasteWaterType.forEach((method) => {
    lineChartDataByType[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueWasteWaterType.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.WastewaterType === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.Quantitym3) ? acc + item.Quantitym3 : acc, 0);
      lineChartDataByType[method].push(sum);
    });
  });
  const colorScaleLineForType = scaleOrdinal(schemeCategory10);
  const datasetsByType = Object.entries(lineChartDataByType).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLineForType(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataByType = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasetsByType
  };

  const legendData1 = [...uniqueWasteWaterType].map((type: any, index: any) => ({
    name: type,
  }));

  const pieData: any = {};
  newData.forEach((item: any) => {
    const wastewaterType = item.WastewaterType;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData[wastewaterType]) {
      pieData[wastewaterType] += quantitym3;
    } else {
      pieData[wastewaterType] = quantitym3;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byWasteWaterTypePieData = Object.keys(pieData).map((wastewaterType, index) => ({
    name: wastewaterType,
    value: pieData[wastewaterType],
    color: colorType5(index.toString()),//colorScale(pieData[wastewaterType] / Math.max(...Object.values(pieData) as number[])),
  }));


  const pieData2: any = {};
  newData.forEach((item: any) => {
    const disposalMethod = item.DisposalMethod;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData2[disposalMethod]) {
      pieData2[disposalMethod] += quantitym3;
    } else {
      pieData2[disposalMethod] = quantitym3;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byWasteWaterDestinationPieData = Object.keys(pieData2).map((wastewaterDestination, index) => ({
    name: wastewaterDestination,
    value: pieData2[wastewaterDestination],
    color: colorType3(index.toString()),//colorScale2(pieData2[wastewaterDestination] / Math.max(...Object.values(pieData2) as number[])),
  }));

  //Table 1
  const wasteWaterTypeLabels = wasteWaterLogColumns.find((col: any) => col.columnName === 'WastewaterType')?.choiceChoices;
  const wasteWaterTypesTable = wasteWaterTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });

  const groupedDataTable = newData.reduce((result, item) => {
    const { Period, WastewaterType, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      wasteWaterTypesTable.forEach((wasteWaterType: any) => {
        result[Period][wasteWaterType] = 0; // Initialize with 0 for each WastewaterType
      });
    }
    result[Period][WastewaterType] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each WastewaterType within each Period
  const transformedDataTable = Object.values(groupedDataTable).map((values: any) => {
    const sumBywasteWaterType = wasteWaterTypesTable.reduce((sum: number, wasteWaterType: string) => sum + values[wasteWaterType], 0);
    return {
      ...values,
      "Total (By Wastewater Type)": sumBywasteWaterType
    };
  });
  const combinedData: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  transformedDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });
  // Convert the combinedData object back to an array
  const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys = mergedData.length > 0 ? Object.keys(mergedData[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];


  //Table 2

  const wasteWaterDisposalMethodLabels = wasteWaterLogColumns.find((col: any) => col.columnName === 'DisposalMethod')?.choiceChoices;
  const wasteWaterDisposalMethodTable = wasteWaterDisposalMethodLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });

  const groupedDataTable2 = newData.reduce((result, item) => {
    const { Period, DisposalMethod, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      wasteWaterDisposalMethodTable.forEach((wasteWaterDisposalMethod: any) => {
        result[Period][wasteWaterDisposalMethod] = 0; // Initialize with 0 for each DisposalMethod
      });
    }
    result[Period][DisposalMethod] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
  const transformedDataTable2 = Object.values(groupedDataTable2).map((values: any) => {
    const sumBywasteWaterDisposalMethod = wasteWaterDisposalMethodTable.reduce((sum: number, wasteWaterDisposalMethod: string) => sum + values[wasteWaterDisposalMethod], 0);
    return {
      ...values,
      "Total (By Disposal Method)": sumBywasteWaterDisposalMethod
    };
  });

  const combinedData2: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  transformedDataTable2.forEach(item => {
    const { Period, ...rest } = item;
    combinedData2[Period] = { ...combinedData2[Period], ...rest };
  });
  // Convert the combinedData object back to an array
  const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];






  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = newData.reduce((total, item) => {
    if (item.Quantitym3) {
      return total + item.Quantitym3;
    }
    return total;
  }, 0);
  return (
    <View style={logStyles.logRoot}>

      <View style={CommonStyles.ribbonIconContainer}>
        <View style={CommonStyles.IconContainer}>
          <FilterIcon setFilterVisible={setFilterVisible} FilterVisible={FilterVisible} />
        </View>
        <View style={CommonStyles.IconContainer}>
          {
            EnableExport() === true ?
              <ExportIcon handleExportClick={handleExportClick} newData={newData} />
              : null
          }
        </View>
        <SelectedFilters selectedMonth={selectedMonth} selectedPackage={selectedPackage} selectedYear={selectedYear} />
      </View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Wastewater (m3)'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={FilterVisible}
        onRequestClose={() => {
          setFilterVisible(!FilterVisible);
        }}>
        <View style={formCustomStyles.centeredView}>
          {/* <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
            <View>
              <Tooltip title="Close">
                <IconButton
                  style={{ marginLeft: screenWidth - 60 }} 
                  icon="close-circle-outline"
                  iconColor={MD3Colors.error0}
                  size={20}
                  onPress={() => setFilterVisible(!FilterVisible)}
                />
              </Tooltip>
            </View>

            <View style={[CommonStyles.container]} key={0}>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={yearFilterOptions}
                  label={"Filter by Year"}
                  onSelect={handleYearFilterSelect}
                  selected={selectedYear}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={monthFilterOptions}
                  label={"Filter by Month"}
                  onSelect={handleMonthFilterSelect}
                  selected={selectedMonth}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={packageFilterOptions}
                  label={"Filter by Package"}
                  onSelect={handlePackageFilterSelect}
                  selected={selectedPackage}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <ButtonControl icon="filter-remove" mode="contained"
                  onPress={() => {handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData); setFilterVisible(false)}}
                  Title='Clear' disabled={false} />
              </View>
            </View>
          </BlurView> */}
          <FilterBox
            FilterVisible={FilterVisible}
            setFilterVisible={setFilterVisible}
            screenWidth={screenWidth}
            CommonStyles={CommonStyles}
            yearFilterOptions={yearFilterOptions}
            handleYearFilterSelect={handleYearFilterSelect}
            selectedYear={selectedYear}
            monthFilterOptions={monthFilterOptions}
            handleMonthFilterSelect={handleMonthFilterSelect}
            selectedMonth={selectedMonth}
            packageFilterOptions={packageFilterOptions}
            handlePackageFilterSelect={handlePackageFilterSelect}
            selectedPackage={selectedPackage}
            handleFilterReset={handleFilterReset}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            setSelectedPackage={setSelectedPackage}
            setNewData={setNewData}
            updatedData={updatedData}
          />
        </View>
      </Modal>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>

      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                <View style={CommonStyles.container}>
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byWasteWaterTypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - m3)'} ChartTitle={"Qty / Type"} /></View>
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataByType} label={uniquePeriod} subTitle={'(Monthly - m3)'} ChartTitle={"Qty / Type"} /></View>

                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byWasteWaterDestinationPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - m3)'} ChartTitle={"Qty / Destination"} /></View>
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData} label={uniquePeriod} subTitle={'(Cumulative - m3)'} ChartTitle={"Qty / Destination"} /></View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>

          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                <View style={CommonStyles.container}>
                  <View style={CommonStyles.cardTwo}>
                    {
                      newData &&
                      newData.length > 0 &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={true}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};

