import React, { useState } from 'react';
import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { MultiselectDropdown, Dropdown } from 'sharingan-rn-modal-dropdown';
import { Control, Controller } from 'react-hook-form';
import { IconButton } from 'react-native-paper';

interface DropdownField {
  key: string;
  label: string;
  defaultValue?: string;
  options: string;
  required:boolean
}

interface SingleDropdownProps {
  field: DropdownField;
  control: Control<any>;
  editable: boolean;
  Ctrl: typeof Controller;
  errors:any;
}

interface MultiDropdownProps {
  field: DropdownField;
  control: Control<any>;
  Ctrl: typeof Controller;

}

const DropdownControl: React.FC<SingleDropdownProps> = ({ field, control, editable, Ctrl, errors }) => {
  const rule = field.required ? { required: field.label + ' is required' } : { required: false};
  
  const options = field.options;
  const [items, setItems] = useState(
    options.includes('#;#')
      ? options.split('#;#').map((item, index) => {
          return {
            label: item.trim(),
            value: item.trim(),
            key: index.toString(),
          };
        })
      : [{ label: options.trim(), value: options.trim(), key: '0' }]
  );
  const [selectedVal, setSelectedVal] = useState(null)
  function handleDropdownChange(selectedValue:string)
  {
    console.log(selectedValue)  
    //debugger;
  }
  return (
    <View style={{ flexDirection: 'column' }}>
      <ScrollView>
      <View style={{width:'100%'}}><Text style={{fontWeight:'bold', fontSize:16}}>{field.label}</Text></View>
      
        <View style={styles.container}>
        {editable === false ? <Text style={{fontWeight:'normal', fontSize:12}}>{field.defaultValue ?? null}</Text> :
          <Ctrl
            key={field.key}
            control={control}
            name={field.key}
            //rules={field.required === true ? { required: field.label + ' is required' } : null}
            //rules={field.required ? { required: field.label + ' is required' } : {}}
            rules={rule}
            defaultValue={field.defaultValue ?? null}
            render={({ field:{ onChange, onBlur, value} }) => (
              <Dropdown
                label={selectedVal === null ? 'Select' : 'Selected'}
                data={items}
                enableSearch
                value={value}
                onChange={(selectedValue) => {onChange(selectedValue);setSelectedVal(selectedValue)}}
                selectedItemTextStyle={{ fontWeight: 'bold', color:'#ffffff' }}
                disableSelectionTick = {true}
                //itemSelectIcon={'check'}
                selectedItemViewStyle = {{backgroundColor:'#004876'}}
                //textInputStyle={[styles.dropdown,styles.looks]}
                textInputStyle={[styles.looksV2]}
                disabled={!editable}
                onBlur={onBlur}
                //mainContainerStyle={{}}
                dropdownIcon={'menu-down-outline'}
                dropdownIconSize={30}
                //required={true}
                //enableAvatar
              />
            )}
          />
            }
          {/* {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>} */}
        </View>
        {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
      </ScrollView>
    </View>
  );
};

const MultiDropdownControl: React.FC<MultiDropdownProps> = ({
  field,
  control,
  Ctrl,
}) => {
  const [valueMS, setValueMS] = useState('');
  const onChangeMS = (value: string) => {
    setValueMS(value);
  };
  const options = field.options;
  const [items, setItems] = useState(
    options.includes('#;#')
      ? options.split('#;#').map((item, index) => {
          return {
            label: item.trim(),
            value: item.trim(),
            key: index.toString(),
          };
        })
      : [{ label: options.trim(), value: options.trim(), key: '0' }]
  );

  return (
    <View style={{ flexDirection: 'column' }}>
      <ScrollView>
        <View style={styles.container}>
          <Ctrl
            key={field.key}
            control={control}
            name={field.key}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <MultiselectDropdown
                label={field.label}
                data={items}
                enableSearch
                chipType="outlined"
                value={value}
                onChange={onChange}
                textInputStyle={styles.dropdown}
              />
            )}
          />
          {/* {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>} */}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  dropdown: {
    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
  },
  buttonView: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  looks:{
        backgroundColor: 'transparent',
        marginVertical: 5,
        marginBottom: 30,
        borderWidth: 1,
        //borderWidth: 0.5, // Set the border width
        borderColor: 'gray', // Set the border color
        borderRadius: 5, // Set the border radius (optional)
        shadowColor: 'blue', // Set the shadow color
        shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
        shadowOpacity: 0.5, // Set the shadow opacity
        shadowRadius: 4, // Set the shadow radius
        elevation: 4
  },
  looksV2:{
    backgroundColor: 'transparent',
    //marginVertical: 5,
    //marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4
}
});

export { DropdownControl, MultiDropdownControl }