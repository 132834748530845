import React from "react";
import { View, Text } from "react-native";
import { DatePickerInput } from 'react-native-paper-dates';
interface PaperDatesProps {
  field: any;
  control: any;
  editable: boolean;
  styles: any;
  Ctrl: any;
  errors:any;

}

const PaperDates: React.FC<PaperDatesProps> = ({ field, control, editable, styles, Ctrl, errors }) => {
  const rule = field.required ? { required: field.label + ' is required' } : { required: false};
  const [inputDate, setInputDate] = React.useState(undefined);

let date = null;
if (field.defaultValue && field.defaultValue !== null)
{
  const dateString = field.defaultValue;
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);
  date = new Date(year, month, day);
  console.log("Inside control: " + field.defaultValue + " = " +  date)
}

  return (
    <View style={{ justifyContent: 'center', flex: 1, width: '100%' }}>
      <View style={{width:'100%'}}><Text style={{fontWeight:'bold', fontSize:16}}>{field.label}</Text></View>
      
      {editable === false ? <View><Text style={{fontWeight:'normal', fontSize:12}}>{field.defaultValue ?? null}</Text></View> :
      <Ctrl
        key={field.key}
        control={control}
        name={field.key}
        rules={rule}
        defaultValue={field.defaultValue && date && date !== null ? date : null}
        render={({ field: { onChange, onBlur, value } }: { field: { onChange: any, onBlur: any, value: any } }) => (
          <DatePickerInput
            locale="en-gb"
            label={null}
            value={value}
            onChange={(date) => onChange(date)}
            inputMode="start"
            style={styles}
            editable={editable}
            disabled={!editable}
            //date={field.defaultValue && field.defaultValue}
            onBlur={onBlur}
            //ref={ref}
          />
        )}
      />
        }
        {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
    </View>
  );
};

export default PaperDates;
