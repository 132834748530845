import React, { useState } from 'react';
import { StyleSheet} from 'react-native';
import { Dropdown, IDropdownData } from 'sharingan-rn-modal-dropdown';
interface Props {
    selected: string;
    label:string;
    options:Array<any>;
    onSelect:(val:string)=>void;
}
const SingleSelectDropdown : React.FC<Props> = React.memo((props) => {
    const [selectedValue, setSelectedValue] = useState<string>(props.selected);
    function handleSelect(val:string){
        setSelectedValue(val);
        props.onSelect(val);
    }
    return (
        props &&
        <Dropdown
            label={props.selected ==="" ? props.label:""}
            //label={props.label}
            data={props.options}
            disableSort
            value={selectedValue}
            onChange={(val) => handleSelect(val)}
            selectedItemTextStyle={{ fontWeight: 'bold', color:'#ffffff' }}
            disableSelectionTick = {true}
            selectedItemViewStyle = {{backgroundColor:'#004876'}}
            //textInputStyle={styles.dropdown}
            mode="outlined"
        />
    );
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "transparent"
    },
    dropdown: {
        backgroundColor: "transparent",
        //marginVertical: 5,
        //marginBottom: 30,
        borderWidth: 1,
        height:40
    },
    buttonView: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 10,
    },

});
export { SingleSelectDropdown }