import * as React from 'react';
//import { AppRegistry } from 'react-native';
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { AuthProvider } from './providers/AuthProvider';
import Landing from './components/Landing';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ClientProvider } from './providers/ClientProvider';
import { NavProvider } from './providers/NavProvider';
//import { name as appName } from './app.json';

const theme = {
  ...DefaultTheme,
  "colors": {
    "primary": "rgb(28, 96, 165)",
    "onPrimary": "rgb(255, 255, 255)",
    "primaryContainer": "rgb(212, 227, 255)",
    "onPrimaryContainer": "rgb(0, 28, 58)",
    "secondary": "rgb(84, 95, 113)",
    "onSecondary": "rgb(255, 255, 255)",
    "secondaryContainer": "rgb(216, 227, 248)",
    "onSecondaryContainer": "rgb(17, 28, 43)",
    "tertiary": "rgb(109, 86, 118)",
    "onTertiary": "rgb(255, 255, 255)",
    "tertiaryContainer": "rgb(246, 217, 255)",
    "onTertiaryContainer": "rgb(39, 20, 48)",
    "error": "rgb(186, 26, 26)",
    "onError": "rgb(255, 255, 255)",
    "errorContainer": "rgb(255, 218, 214)",
    "onErrorContainer": "rgb(65, 0, 2)",
    "background": "rgb(253, 252, 255)",
    "onBackground": "rgb(26, 28, 30)",
    "surface": "rgb(253, 252, 255)",
    "onSurface": "rgb(26, 28, 30)",
    "surfaceVariant": "rgb(223, 226, 235)",
    "onSurfaceVariant": "rgb(67, 71, 78)",
    "outline": "rgb(115, 119, 127)",
    "outlineVariant": "rgb(195, 198, 207)",
    "shadow": "rgb(0, 0, 0)",
    "scrim": "rgb(0, 0, 0)",
    "inverseSurface": "rgb(47, 48, 51)",
    "inverseOnSurface": "rgb(241, 240, 244)",
    "inversePrimary": "rgb(164, 200, 255)",
    "elevation": {
      "level0": "transparent",
      "level1": "rgb(242, 244, 251)",
      "level2": "rgb(235, 240, 248)",
      "level3": "rgb(228, 235, 245)",
      "level4": "rgb(226, 233, 244)",
      "level5": "rgb(222, 230, 242)"
    },
    "surfaceDisabled": "rgba(26, 28, 30, 0.12)",
    "onSurfaceDisabled": "rgba(26, 28, 30, 0.38)",
    "backdrop": "rgba(45, 49, 56, 0.4)"
  }

};


export default function Main() {
  return (
    <SafeAreaProvider>
    <PaperProvider theme={theme}>
      <AuthProvider>
        <ClientProvider>
          <NavProvider>
        {/* <SafeAreaView> */}
        
          <Landing />
        
        {/* <DatePicker/> */}
        {/* </SafeAreaView> */}
        </NavProvider>
        </ClientProvider>
      </AuthProvider>

    </PaperProvider>
    </SafeAreaProvider>
  );
}

//AppRegistry.registerComponent(appName, () => Main);