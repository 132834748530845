import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox, Dimensions, Modal } from 'react-native';
import { WebView } from 'react-native-webview'
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
//import noiseData from './NoiseMonitoringData';
import LineChartScreen from '../common/charts/lineChart-builder';
import BarChartsComp from '../common/charts/barChart-builder';
import ThreeColumnLayout from '../common/layouts/ThreeColumnLayout'
import OneColumnLayout from '../common/layouts/OneColumnLayout';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import BarChartJS from '../common/charts/BarChartJS';
import FlexDirectionHandler from '../common/layouts/FlexHandler';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import GooglePieChartComp from '../common/charts/google-pie';
import VictoryCharts from '../common/charts/chart-victory';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import ToastMessage from '../common/controls/Toast';
import ButtonControl from '../common/controls/Button';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters } from './ReUsableComps';
import { BlurView } from 'expo-blur';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import formCustomStyles from '../common/forms/formStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ConsolNoiseLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [noiseLogData, setNoiseLogData] = useState<null | any>(null);
  const [noiseLogColumns, setNoiseLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setNoiseLogData(finalData.items);
        setNoiseLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return noiseLogData && noiseLogColumns ? <CreateNoiseLogCharts setNoiseLogData={setNoiseLogData} noiseLogData={noiseLogData} noiseLogColumns={noiseLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : null;
}
interface CreateNoiseLogChartsProps {
  noiseLogData: any;
  noiseLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setNoiseLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setNoiseLogData, noiseLogData, noiseLogColumns, siteId, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();

  const [formMode, setformMode] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  //setformMode={} setSelectedRows={} setShowPanel={}
  const ColumnsWithIDColumn = noiseLogColumns && [
    ...noiseLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  const sortedData = [...noiseLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const maxLimit: number = Math.max(
      parseFloat(item.MonitoringResults1),
      parseFloat(item.MonitoringResults2),
      parseFloat(item.MonitoringResults3)
    );

    const NoiseLevel: number =
      item.Hour24H === ''
        ? null
        : item.TimePeriod === 'Day' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
          ? 75
          : item.TimePeriod === 'Evening' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
            ? 65
            : item.TimePeriod === 'Night' && ['Quiet', 'Sensitive', 'Mixed'].includes(item.AreaClassification)
              ? 45
              : item.TimePeriod === 'Day' && item.AreaClassification === 'Category A'
                ? 50
                : item.TimePeriod === 'Day' && item.AreaClassification === 'Category B'
                  ? 55
                  : item.TimePeriod === 'Day' && item.AreaClassification === 'Category C'
                    ? 60
                    : item.TimePeriod === 'Day' && item.AreaClassification === 'Category D'
                      ? 65
                      : item.TimePeriod === 'Day' && item.AreaClassification === 'Roadsides'
                        ? 70
                        : item.TimePeriod === 'Night' && item.AreaClassification === 'Category A'
                          ? 40
                          : item.TimePeriod === 'Night' && item.AreaClassification === 'Category B'
                            ? 45
                            : item.TimePeriod === 'Night' && item.AreaClassification === 'Category C'
                              ? 50
                              : item.TimePeriod === 'Night' && item.AreaClassification === 'Category D'
                                ? 55
                                : item.TimePeriod === 'Night' && item.AreaClassification === 'Roadsides'
                                  ? 65
                                  : 80;

    const Conformity = maxLimit >= NoiseLevel ? 'Conformity' : 'Non Conformity';
    const pieKey = Conformity;
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Conformity, Period, maxLimit, key, Date, pieKey };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }

  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const datasets = Object.values(lineChartData).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets
  };
  const conformityTypes = new Set();
  conformityTypes.add('Conformity');
  conformityTypes.add('Non Conformity');
  const legendData1 = [...conformityTypes].map((type: any, index: any) => ({
    name: type,
  }));

  const dayNoise = newData.filter((a) => a.TimePeriod == 'Day');
  const eveNoise = newData.filter((a) => a.TimePeriod == 'Evening');
  const nightNoise = newData.filter((a) => a.TimePeriod == 'Night');


  const pieDataTotal: any = {};
  updatedData.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataTotal[Key]) {
      pieDataTotal[Key] += 1;
    } else {
      pieDataTotal[Key] = 1;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byConformityPieData = Object.keys(pieDataTotal).map((key, index) => ({
    name: key,
    value: pieDataTotal[key],
    color: colorType5(index.toString()),//colorScale2(pieDataTotal[key] / Math.max(...Object.values(pieDataTotal) as number[])),
  }));



  /**======================================================= */
  const pieDataDay: any = {};
  dayNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataDay[Key]) {
      pieDataDay[Key] += 1;
    } else {
      pieDataDay[Key] = 1;
    }
  });
  const colorScale3 = scaleSequential().domain([0, 1]).interpolator(interpolateBuGn);
  const byConformityPieDataDay = Object.keys(pieDataDay).map((key, index) => ({
    name: key,
    value: pieDataDay[key],
    color: colorType5(index.toString()),//colorScale3(pieDataDay[key] / Math.max(...Object.values(pieDataDay) as number[])),
  }));



  /**======================================================= */
  /**======================================================= */

  const pieDataEve: any = {};
  eveNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0

    if (pieDataEve[Key]) {
      pieDataEve[Key] += 1;
    } else {
      pieDataEve[Key] = 1;
    }
  });
  const colorScale4 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byConformityPieDataEve = Object.keys(pieDataEve).map((key, index) => ({
    name: key,
    value: pieDataEve[key],
    color: colorType3(index.toString()),//colorScale4(pieDataEve[key] / Math.max(...Object.values(pieDataEve) as number[])),
  }));



  /**======================================================= */
  /**======================================================= */

  const pieDataNight: any = {};
  nightNoise.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0

    if (pieDataNight[Key]) {
      pieDataNight[Key] += 1;
    } else {
      pieDataNight[Key] = 1;
    }
  });
  const colorScale5 = scaleSequential().domain([0, 1]).interpolator(interpolateBuPu);
  const byConformityPieDataNight = Object.keys(pieDataNight).map((key, index) => ({
    name: key,
    value: pieDataNight[key],
    color: colorType5(index.toString()),//colorScale5(pieDataNight[key] / Math.max(...Object.values(pieDataNight) as number[])),
  }));



  /**======================================================= */


  const lineChartConformityDataDay: number[] = [];
  const lineChartNonConformityDataDay: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = dayNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = dayNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataDay.push(filteredDataConf);
    lineChartNonConformityDataDay.push(filteredDataNonConf);
  });

  const lineChartDataDay = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);
  const datasets2 = Object.values(lineChartDataDay).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataDayChart = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: datasets2
  };

  const lineChartConformityDataEve: number[] = [];
  const lineChartNonConformityDataEve: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = eveNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = eveNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataEve.push(filteredDataConf);
    lineChartNonConformityDataEve.push(filteredDataNonConf);
  });

  const lineChartDataEve = {
    Line1: lineChartConformityDataEve,
    Line2: lineChartNonConformityDataEve
  };

  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const datasets3 = Object.values(lineChartDataEve).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataEveChart = {
    labels: uniquePeriod,
    datasets: datasets3
  };


  const lineChartConformityDataNight: number[] = [];
  const lineChartNonConformityDataNight: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = nightNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = nightNoise
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityDataNight.push(filteredDataConf);
    lineChartNonConformityDataNight.push(filteredDataNonConf);
  });

  const lineChartDataNight = {
    Line1: lineChartConformityDataNight,
    Line2: lineChartNonConformityDataNight
  };

  const colorScaleLine4 = scaleOrdinal(schemeCategory10);
  const datasets4 = Object.values(lineChartDataNight).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine4(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataNightChart = {
    labels: uniquePeriod,
    datasets: datasets4
  };


  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = newData.length;
  return (
    <View style={logStyles.logRoot}>

      
      <View style={CommonStyles.ribbonIconContainer}>
        <View style={CommonStyles.IconContainer}>
          <FilterIcon setFilterVisible={setFilterVisible} FilterVisible={FilterVisible} />
        </View>
        <View style={CommonStyles.IconContainer}>
          {
            EnableExport() === true ?
              <ExportIcon handleExportClick={handleExportClick} newData={newData} />
              : null
          }
        </View>
        <SelectedFilters selectedMonth={selectedMonth} selectedPackage={selectedPackage} selectedYear={selectedYear} />
      </View>
      <View style={[{justifyContent: 'center', alignItems:'center'}]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Noise Monitoring'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={FilterVisible}
        onRequestClose={() => {
          setFilterVisible(!FilterVisible);
        }}>
        <View style={formCustomStyles.centeredView}>
          {/* <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
            <View>
              <Tooltip title="Close">
                <IconButton
                  style={{ marginLeft: screenWidth - 60 }} 
                  icon="close-circle-outline"
                  iconColor={MD3Colors.error0}
                  size={20}
                  onPress={() => setFilterVisible(!FilterVisible)}
                />
              </Tooltip>
            </View>

            <View style={[CommonStyles.container]} key={0}>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={yearFilterOptions}
                  label={"Filter by Year"}
                  onSelect={handleYearFilterSelect}
                  selected={selectedYear}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={monthFilterOptions}
                  label={"Filter by Month"}
                  onSelect={handleMonthFilterSelect}
                  selected={selectedMonth}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={packageFilterOptions}
                  label={"Filter by Package"}
                  onSelect={handlePackageFilterSelect}
                  selected={selectedPackage}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <ButtonControl icon="filter-remove" mode="contained"
                  onPress={() => {handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData); setFilterVisible(false)}}
                  Title='Clear' disabled={false} />
              </View>
            </View>
          </BlurView> */}
          <FilterBox
            FilterVisible={FilterVisible}
            setFilterVisible={setFilterVisible}
            screenWidth={screenWidth}
            CommonStyles={CommonStyles}
            yearFilterOptions={yearFilterOptions}
            handleYearFilterSelect={handleYearFilterSelect}
            selectedYear={selectedYear}
            monthFilterOptions={monthFilterOptions}
            handleMonthFilterSelect={handleMonthFilterSelect}
            selectedMonth={selectedMonth}
            packageFilterOptions={packageFilterOptions}
            handlePackageFilterSelect={handlePackageFilterSelect}
            selectedPackage={selectedPackage}
            handleFilterReset={handleFilterReset}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            setSelectedPackage={setSelectedPackage}
            setNewData={setNewData}
            updatedData={updatedData}
          />
        </View>
        <View>
          <ToastMessage
            show={toastVisibility}
            actionLabel="Dismiss"
            toastMessage={toastText}
            duration={3000}
            onDismiss={handleToastDismiss}
          />
        </View>
      </Modal>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                  <View style={CommonStyles.container}>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieData} colorPallate={interpolateBlues} subTitle={'(Day/Eve/Night)'} ChartTitle={"Total"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Day/Eve/Night)'} ChartTitle={"Total"} /></View>
                  
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataDay} colorPallate={interpolateBlues} subTitle={'(Day)'} ChartTitle={"Total"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataEve} colorPallate={interpolateBlues} subTitle={'(Evening)'} ChartTitle={"Total"} /></View>
                    <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieDataNight} colorPallate={interpolateBlues} subTitle={'(Night)'} ChartTitle={"Total"} /></View>
                  </View>
                
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataDayChart} label={uniquePeriod} subTitle={'(Day)'} ChartTitle={"Total"} /></View>
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataEveChart} label={uniquePeriod} subTitle={'(Evening)'} ChartTitle={"Total"} /></View>
                  <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataNightChart} label={uniquePeriod} subTitle={'(Night)'} ChartTitle={"Total"} /></View>
                </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                <View style={CommonStyles.container}>
                  {
                    FilterVisible === true ? null :
                      <View style={CommonStyles.cardThree}>
                        {
                          newData &&
                          newData.length > 0 &&
                          ColumnsWithIDColumn && (
                            <PaperTableDynamic
                              data={newData}
                              columns={ColumnsWithIDColumn}
                              siteId={siteId}
                              logTitle={logTitle}
                              updateData={handleDataUpdate}
                              isConsolidatedTable={true}
                            />
                          )
                        }
                      </View>
                  }
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};