import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu, schemeAccent, schemeSet2, schemePaired, schemeSet3 } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import BarChartsComp from '../common/charts/barChart-builder';
import { Loader } from '../../Common/Loader';
import { colorType3, colorType5, logStyles } from './LogStyles';
import LogFilter from './LogFilterDropdown';

import {
  Tabs,
  TabScreen,
  TabsProvider,
  useTabIndex,
  useTabNavigation,
} from 'react-native-paper-tabs';
import GroupBarChartsComp from '../common/charts/barChart-builder-Grouped';
import LogCountCard from './LogCountCard';


interface Props {
  siteId: string;
  logTitle: string;
}
export default function TrainingLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [trainingLogData, setTrainingLogData] = useState<null | any>(null);
  const [trainingLogColumns, setTrainingLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setTrainingLogData(json.items);
        setTrainingLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return trainingLogData && trainingLogColumns ? <CreateLogCharts setTrainingLogData={setTrainingLogData} trainingLogData={trainingLogData} trainingLogColumns={trainingLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateLogChartsProps {
  trainingLogData: any;
  trainingLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setTrainingLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ setTrainingLogData, trainingLogData, trainingLogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = trainingLogColumns && [
    ...trainingLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];

  const sortedData = [...trainingLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, key, Date };
  });

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkReused(div: string) {
    switch (div) {
      case "Reuse on-site": return "Reuse";
      case "Reuse off-site": return "Reuse";
      case "Temporary storage for future reuse": return "Reuse";

      case "Disposal off-site": return "Not Reused";
      case "Landfill": return "Not Reused";
      case "Slurry lagoon": return "Not Reused";
      case "Other": return "Not Reused";
      default: return null
    }
  }
  function handleYearFilterSelect(val: string) {
    let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const Period = item.Date && GetPeriod(item.Date);
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem, Period];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  /**
   * Creating Line Chart 1
   */
  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const categoryValues = [...new Set(newData.map((item: any) => item.Category))].filter(type => type !== null);
  const conformityData: any = {};

  categoryValues.forEach((category) => {
    conformityData[category] = [];

    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Category === category)
        .length;

      conformityData[category].push(filteredData);
    });
  });

  const datasets = Object.entries(conformityData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(newData.filter(type => type.Period !== null).map((item: any) => item.Period))],//[...new Set(newData.map((item: any) => item.Period))],
    datasets,
  };
  const legendData1 = [...categoryValues].map((type: any, index: any) => ({
    name: type,
  }));



  /**
   * Creating BarChart 1
   */

  const colorScaleBar1 = scaleOrdinal(schemeCategory10);
  const TrainingData: any = {};
  TrainingData["Trainings"] = [];
  uniquePeriod.forEach((period) => {
    const filteredData = newData
      .filter((item) => item.Period === period)
      .length;

    TrainingData["Trainings"].push(filteredData);
  });

  const BarDatasets1 = Object.entries(TrainingData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
  }));

  const BarData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: BarDatasets1,
  };



  /**
   * Creating BarChart 2
   */
  const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  };
  const colorScaleBar2 = scaleOrdinal(schemeCategory10);
  const AttendeesData: any = {};
  AttendeesData["Attendees"] = [];
  uniquePeriod.forEach((period) => {
    const filteredData = newData
      .filter((item) => item.Period === period)
      .reduce((acc, item) => isValidNumber(item.NoOfAttendees) ? acc + item.NoOfAttendees : acc, 0);

    AttendeesData["Attendees"].push(filteredData);
  });

  const BarDatasets2 = Object.entries(AttendeesData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleBar2(index) + Math.round(opacity * 255).toString(16),
  }));

  const BarData2 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: BarDatasets2,
  };


  const GroupedBarData = {
    labels:BarData1.labels,
    datasets:[BarDatasets1, BarDatasets2]
  }
  /**
   * Pie Chart Jan
   */
  // const colorType1 = scaleOrdinal(schemeAccent);
  // const colorType2 = scaleOrdinal(schemeSet2);
  // const colorType3 = scaleOrdinal(schemePaired);
  // const colorType4 = scaleOrdinal(schemeCategory10);
  // const colorType5 = scaleOrdinal(schemeSet3);

  const pieDataJanTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("01") || item.Period.includes("Jan"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJanTrainingSubject[Key]) {
      pieDataJanTrainingSubject[Key] += 1;
    } else {
      pieDataJanTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJan = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JanTrainingSubjectPieData = Object.keys(pieDataJanTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJanTrainingSubject[key],
    //color: colorScaleJan(pieDataJanTrainingSubject[key] / Math.max(...Object.values(pieDataJanTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart Feb
   */
  const pieDataFebTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("02") || item.Period.includes("Feb"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataFebTrainingSubject[Key]) {
      pieDataFebTrainingSubject[Key] += 1;
    } else {
      pieDataFebTrainingSubject[Key] = 1;
    }
  });
  const colorScaleFeb = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  
  const FebTrainingSubjectPieData = Object.keys(pieDataFebTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataFebTrainingSubject[key],
    //color: colorScaleFeb(pieDataFebTrainingSubject[key] / Math.max(...Object.values(pieDataFebTrainingSubject) as number[])),
    color: colorType3(key),
  }));

  /**
   * Pie Chart Mar
   */
  const pieDataMarTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("03") || item.Period.includes("Mar"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataMarTrainingSubject[Key]) {
      pieDataMarTrainingSubject[Key] += 1;
    } else {
      pieDataMarTrainingSubject[Key] = 1;
    }
  });
  const colorScaleMar = scaleSequential().domain([1, 0]).interpolator(interpolateCool);
  const MarTrainingSubjectPieData = Object.keys(pieDataMarTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataMarTrainingSubject[key],
    //color: colorScaleMar(pieDataMarTrainingSubject[key] / Math.max(...Object.values(pieDataMarTrainingSubject) as number[])),
    color: colorType5(key),
  }));
  /**
   * Pie Chart Apr
   */
  const pieDataAprTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("04") || item.Period.includes("Apr"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataAprTrainingSubject[Key]) {
      pieDataAprTrainingSubject[Key] += 1;
    } else {
      pieDataAprTrainingSubject[Key] = 1;
    }
  });
  const colorScaleApr = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const AprTrainingSubjectPieData = Object.keys(pieDataAprTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataAprTrainingSubject[key],
    //color: colorScaleApr(pieDataAprTrainingSubject[key] / Math.max(...Object.values(pieDataAprTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart May
   */
  const pieDataMayTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("05") || item.Period.includes("May"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataMayTrainingSubject[Key]) {
      pieDataMayTrainingSubject[Key] += 1;
    } else {
      pieDataMayTrainingSubject[Key] = 1;
    }
  });
  const colorScaleMay = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const MayTrainingSubjectPieData = Object.keys(pieDataMayTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataMayTrainingSubject[key],
    //color: colorScaleMay(pieDataMayTrainingSubject[key] / Math.max(...Object.values(pieDataMayTrainingSubject) as number[])),
    color: colorType3(key),
  }));

  /**
   * Pie Chart Jun
   */
  const pieDataJunTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("06") || item.Period.includes("Jun"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJunTrainingSubject[Key]) {
      pieDataJunTrainingSubject[Key] += 1;
    } else {
      pieDataJunTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJun = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JunTrainingSubjectPieData = Object.keys(pieDataJunTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJunTrainingSubject[key],
    //color: colorScaleJun(pieDataJunTrainingSubject[key] / Math.max(...Object.values(pieDataJunTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart Jul
   */
  const pieDataJulTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("07") || item.Period.includes("Jul"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJulTrainingSubject[Key]) {
      pieDataJulTrainingSubject[Key] += 1;
    } else {
      pieDataJulTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJul = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JulTrainingSubjectPieData = Object.keys(pieDataJulTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJulTrainingSubject[key],
    //color: colorScaleJul(pieDataJulTrainingSubject[key] / Math.max(...Object.values(pieDataJulTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart Aug
   */
  const pieDataAugTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("08") || item.Period.includes("Aug"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataAugTrainingSubject[Key]) {
      pieDataAugTrainingSubject[Key] += 1;
    } else {
      pieDataAugTrainingSubject[Key] = 1;
    }
  });
  const colorScaleAug = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const AugTrainingSubjectPieData = Object.keys(pieDataAugTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataAugTrainingSubject[key],
    //color: colorScaleAug(pieDataAugTrainingSubject[key] / Math.max(...Object.values(pieDataAugTrainingSubject) as number[])),
    color: colorType3(key),
  }));

  /**
   * Pie Chart Sep
   */
  const pieDataSepTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("09") || item.Period.includes("Sep"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataSepTrainingSubject[Key]) {
      pieDataSepTrainingSubject[Key] += 1;
    } else {
      pieDataSepTrainingSubject[Key] = 1;
    }
  });
  const colorScaleSep = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const SepTrainingSubjectPieData = Object.keys(pieDataSepTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataSepTrainingSubject[key],
    //color: colorScaleSep(pieDataSepTrainingSubject[key] / Math.max(...Object.values(pieDataSepTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart Oct
   */
  const pieDataOctTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("10") || item.Period.includes("Oct"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataOctTrainingSubject[Key]) {
      pieDataOctTrainingSubject[Key] += 1;
    } else {
      pieDataOctTrainingSubject[Key] = 1;
    }
  });
  const colorScaleOct = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const OctTrainingSubjectPieData = Object.keys(pieDataOctTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataOctTrainingSubject[key],
    //color: colorScaleOct(pieDataOctTrainingSubject[key] / Math.max(...Object.values(pieDataOctTrainingSubject) as number[])),
    color: colorType5(key),
  }));

  /**
   * Pie Chart Nov
   */
  const pieDataNovTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("11") || item.Period.includes("Nov"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataNovTrainingSubject[Key]) {
      pieDataNovTrainingSubject[Key] += 1;
    } else {
      pieDataNovTrainingSubject[Key] = 1;
    }
  });
  const colorScaleNov = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const NovTrainingSubjectPieData = Object.keys(pieDataNovTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataNovTrainingSubject[key],
    //color: colorScaleNov(pieDataNovTrainingSubject[key] / Math.max(...Object.values(pieDataNovTrainingSubject) as number[])),
    color: colorType3(key),
  }));

  /**
   * Pie Chart Dec
   */
  const pieDataDecTrainingSubject: any = {};
  newData.filter((item) => (item.Period) && (item.Period.includes("12") || item.Period.includes("Dec"))).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataDecTrainingSubject[Key]) {
      pieDataDecTrainingSubject[Key] += 1;
    } else {
      pieDataDecTrainingSubject[Key] = 1;
    }
  });
  const colorScaleDec = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const DecTrainingSubjectPieData = Object.keys(pieDataDecTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataDecTrainingSubject[key],
    //color: colorScaleDec(pieDataDecTrainingSubject[key] / Math.max(...Object.values(pieDataDecTrainingSubject) as number[])),
    color: colorType5(key),
  }));


  /**
   * Custom Table 1
   */

  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const itemCount = filteredItems.length;//parseFloat(TotalHaz.length) + parseFloat(TotalNonHaz.length);
    const sumAttendees = filteredItems.reduce((sum, item) => sum + item.NoOfAttendees, 0);
    return { Period, "No. of Trainings": itemCount, "Attendees": sumAttendees };
  });
  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => { return { ...values } });
  const combinedData1: { [key: string]: { [key: string]: number } } = {};
  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });
  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    }
  }) : [];


  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a: any, b: any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]
const TrainingCount = newData.length;
const AttendeesCount = newData.reduce((total, item) => {
  if (isValidNumber(item.NoOfAttendees)) {
    return total + item.NoOfAttendees;
  }
  return total;
}, 0);

  return (
    <View  style={logStyles.logRoot}>
      <TabsProvider
        defaultIndex={0}
      // onChangeIndex={handleChangeIndex} optional
      >
       
          <Tabs
            // uppercase={false} // true/false | default=true (on material v2) | labels are uppercase
            // showTextLabel={false} // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
            // iconPosition // leading, top | default=leading
            style={{ backgroundColor: '#fff', height: "100%" }} // works the same as AppBar in react-native-paper
            // dark={false} // works the same as AppBar in react-native-paper
            // theme={} // works the same as AppBar in react-native-paper
            // mode="scrollable" // fixed, scrollable | default=fixed
            showLeadingSpace={true} //  (default=true) show leading space in scrollable tabs inside the header
          // disableSwipe={false} // (default=false) disable swipe to left/right gestures
          >
            <TabScreen label="Charts" icon="chart-bar" >
              <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View style={[logStyles.container]} key={0}>
                <LogCountCard cardTitle={'Total Trainings'} number={TrainingCount} />
                <LogCountCard cardTitle={'Total Attendees'} number={AttendeesCount} />
                  <View style={logStyles.card}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View>
                <View>
                  <View style={logStyles.container}>
                    <View style={[logStyles.card, { backgroundColor: '' }]}>
                      <LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} ChartTitle={"Training Category"} subTitle={'(Monthly)'} />
                    </View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}>
                      <GroupBarChartsComp data={BarData1} label={uniquePeriod} ChartTitle={"Number of Trainings"} subTitle={'(Monthly)'} />
                    </View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}>
                      {/* <BarChartsComp data={BarData2} label={uniquePeriod} ChartTitle={"Number of Attendees (Monthly)"} /> */}
                      <GroupBarChartsComp data={BarData2} label={uniquePeriod} ChartTitle={"Number of Attendees"} subTitle={'(Monthly)'} />
                    </View>
                  
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={JanTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jan"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={FebTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Feb"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={MarTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Mar"} /></View>
                  
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={AprTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Apr"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={MayTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - May"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={JunTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jun"} /></View>
                  
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={JulTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jul"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={AugTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Aug"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={SepTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Sep"} /></View>
                  
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={OctTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Oct"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={NovTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Nov"} /></View>
                    <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={DecTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Dec"} /></View>
                  </View>
                </View>
              </ScrollView>
            </TabScreen>
            <TabScreen label="Tables" icon="table" >
              <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                  <View style={logStyles.cardThreeFilter}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View>
                <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    {mergedData1 && columnKeys1 && mergedData1.length > 0 ? (
                      <CustomDT
                        data={mergedData1}
                        columns={columnKeys1}
                        siteId={siteId}
                        logTitle={logTitle}
                      />
                    ) : null
                    }
                  </View>
                </View>
                </View>
              </ScrollView>
            </TabScreen>
            <TabScreen
              label="List"
              icon="database">
              <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                  <View style={logStyles.cardThreeFilter}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View>
                <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    {
                      setNewData &&
                      newData &&
                      uniqueYearsArray &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={false}
                        />
                      )
                    }
                  </View>
                </View>
                </View>
              </ScrollView>
            </TabScreen>
          </Tabs>       
      </TabsProvider>
    </View>
  );
};

