import React, { useState } from 'react';
import { View, StyleSheet,ScrollView } from 'react-native';
import Clients, { IClientsResp } from './Clients';
import UserDetails from './Unused/UserDetails';
import { NavigationContainer } from '@react-navigation/native';
import ClientLanding from './ClientLanding';
import Sitelanding from './SiteLanding';
import ListLanding from './ListLanding';

// import { createDrawerNavigator } from '@react-navigation/drawer';
// import CustomDrawer from './CustomDrawer';
import Topbar from './Appbar/Topbar';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ReportLanding from './ReportLanding';
import NotFound from './Common/NotFound';
//import { ScrollView } from 'react-native-gesture-handler';

export type RootStackParams = {
    Clients: undefined;
    NotFound:undefined;
    NotFoundSite: {
        sitePath: string;
        clientShortURL: string;
    },
    Site: {
        sitePath: string;
        clientShortURL: string;
    },
    Client: {
        clientShortURL: string;
    },
    List: {
        sitePath: string;
        clientShortURL: string;
    },
    Report: {
        sitePath: string;
        clientShortURL: string;
    },
 
}
 
const linking = {
    prefixes: ['http://localhost:19006/', 'com.keoic.greenport://',"https://nice-dune-0f6ab0303.3.azurestaticapps.net/","https://dev.greenport.keo.com/"],
    config: {
        screens: {
            Clients: 'clients/',
            Client:'clients/:clientShortURL/',
            Site: 'clients/:clientShortURL/site/:sitePath',
            List: 'clients/:clientShortURL/site/:sitePath/list',
            Report: 'clients/:clientShortURL/site/:sitePath/report',
            NotFoundSite: 'clients/:clientShortURL/*',
            NotFound:'*'
        },
       
    }
};


const RootStack = createNativeStackNavigator();

const Home: React.FC = () => {
    //const { accessToken } = useAuth();
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    // const handleDrawerState = () => {
    //     setIsDrawerOpen(!isDrawerOpen);
    // };

    return (
        //<SafeAreaView>

        <NavigationContainer linking={linking}>
            
            <View style={styles.container}>
                
                <View style={styles.topbarContainer}>
                    <Topbar />
                </View>
                
                <View style={styles.bodyContainer}>
                    <View style={styles.contentContainer}>
                    
                        <View style={styles.navContainer}>
                        
                            {/* <RootStack.Navigator useLegacyImplementation={false} drawerContent={props => <CustomDrawer />} screenOptions={{ headerShown: false}} initialRouteName='Clients'>
                                <RootStack.Screen name="Clients" component={Clients} />
                                <RootStack.Screen name="UserDetails" component={UserDetails} />
                                <RootStack.Screen name="Client" component={ClientLanding} />
                                <RootStack.Screen name="Site" component={Sitelanding} />
                                <RootStack.Screen name="List" component={ListLanding} />
                            </RootStack.Navigator> */}

                            <RootStack.Navigator screenOptions={{ headerShown: false }} initialRouteName='Clients'>
                                <RootStack.Screen name="Clients" component={Clients} />
                                <RootStack.Screen name="NotFound" component={NotFound} />
                                <RootStack.Screen name="Client" component={ClientLanding} />
                                <RootStack.Screen name="Site" component={Sitelanding} />
                                <RootStack.Screen name="NotFoundSite" component={NotFound} />
                                <RootStack.Screen name="List" component={ListLanding} />
                                <RootStack.Screen name="Report" component={ReportLanding} />
                            </RootStack.Navigator>
                        </View>
                    
                    </View>
                    <View style={styles.buttonsContainer}>
                        {/* <RefreshTokenButton />
                        <LogoutButton /> */}
                    </View>
                </View>
            </View>
        </NavigationContainer>
        // </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        //flex: 1,
        width: '100%',
        height: '100%',
        //alignItems: 'center',
        // gap: 20,
        //justifyContent: 'space-between',
        // flexGrow: 1,
        // alignSelf: 'stretch',
        
    },
    bodyContainer: {
        flex: 1,
        justifyContent: 'space-between',
    },
    contentContainer: {
        // flex:1,
        // padding: 10,
        justifyContent: 'flex-start',
        //alignItems: 'center',
        gap: 20,
        //maxWidth:1300,
        alignContent:'center',
    },
    tinyLogo: {
        width: 100,
        height: 15,
    },
    navContainer: {

        // flexGrow: 5,
        //alignItems: 'center'
        // alignItems: 'stretch',
        // backgroundColor: 'red',
        // alignSelf: 'stretch',
        height: '100%',
    },
    buttonsContainer:
    {
        gap: 20,
    },
    topbarContainer: {

    },
});

export default Home;
