import React from "react";
import { View, Text } from "react-native";
import { SingleSelectDropdown } from "../common/controls/Dropdown-filter";
import { logStyles } from "./LogStyles";

interface Props {
    selected: string;
    label: string;
    options: Array<any>;
    onSelect: (val: string) => void;
}

const LogFilter: React.FC<Props> = ({ options, label, onSelect, selected }) => {
    return (
        <View>
            {/* <View style={{ padding: logStyles.container.padding }}>
                <Text>{'Filter by year'}</Text>
            </View> */}
            <View style={[logStyles.container]}>
                <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{'Filter by year'}</Text>
                <SingleSelectDropdown
                    options={options}
                    label={''}
                    onSelect={onSelect}
                    selected={selected}
                />
            </View>
        </View>
    )
}

export default LogFilter ;