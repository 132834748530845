import * as React from 'react';
import { Appbar } from 'react-native-paper';
import { View, StyleSheet, Image } from 'react-native';
// import { DrawerActions } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParams } from '../Home';
import { useNav } from '../../providers/NavProvider';
import { useRoute } from '@react-navigation/native';
import UserAvatar from './UserAvatar';
import AvatarMenu from './AvatarMenu';


const Topbar: React.FC = ({ }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { currentLeafObj } = useNav();
    return (
        <View style={{backgroundColor:'#4BB75F'}}>
            {/* {currentLeafObj ? */}
            {/* // <Appbar> */}
            <Appbar.Header elevated >
                <View style={{backgroundColor:'#4BB75F', flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems: 'center' }}>
                    {/* <Appbar.Action icon="menu" onPress={() => { navigation.dispatch(DrawerActions.toggleDrawer()) }} /> */}
                    <View style={{ minWidth: 50 }}>
                        {(navigation.canGoBack() && currentLeafObj && currentLeafObj.path.split('-').length > 2) && <Appbar.Action icon="arrow-left" onPress={() => { navigation.goBack() }} />}
                        {/* <Appbar.Content title={currentLeafObj ? currentLeafObj.title : 'Greenport'} ></Appbar.Content> */}
                    </View>
                    <Image style={styles.tinyLogo} source={require('../../assets/Greenport-white.png')}></Image>
                    {/* <Image style={styles.tinyLogo} source={require('../../assets/logo.png')}></Image> */}
                    {/* <Appbar.Action icon="calendar" onPress={() => { }} />
            <Appbar.Action icon="magnify" onPress={() => { }} /> */}
                    {/* <UserAvatar/> */}
                    <View>
                        <AvatarMenu />
                    </View>
                </View>
            </Appbar.Header>
            {/* // </Appbar> */}
            {/* : <></>
            } */}
        </View>
    );
};


const styles = StyleSheet.create({

    tinyLogo: {
        margin:14,
        width: 216,
        height:30,
        alignSelf: 'center'
    }

});
export default Topbar;