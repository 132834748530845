import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox,ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { Loader } from '../../Common/Loader';
import { logStyles } from './LogStyles';

interface Props {
  siteId: string;
  logTitle: string;
}
export default function PermitRegister(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {

        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setLogData(json.items);
        setLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return LogData && LogColumns ? <CreateNoiseLogCharts setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setLogData, LogData, LogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();

  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const updatedData = LogData.map((item: any) => {
    const key = item.ID;
    const PermitValidity = item.PermitValidity && returnDate(item.PermitValidity);
    return { ...item, PermitValidity, key };
  });



  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      console.log(formattedDate)
      return formattedDate;
    } else {
      return null;
    }
  }
  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const newItem = { ...result.items[0] }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }

  return (
    <View style={logStyles.logRoot}>
      <ScrollView>
        <View style={logStyles.container}>
          <View style={logStyles.cardTwo}>
            {
              newData &&
              ColumnsWithIDColumn && (
                <PaperTableDynamic
                  data={newData}
                  columns={ColumnsWithIDColumn}
                  siteId={siteId}
                  logTitle={logTitle}
                  updateData={handleDataUpdate}
                  isConsolidatedTable={false}
                />
              )
            }
          </View>
        </View>
      </ScrollView>
    </View>
  );
};