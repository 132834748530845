import React, { useState } from 'react';
import { View, Text, LayoutChangeEvent, Platform, Dimensions, Modal, ScrollView, TouchableOpacity } from 'react-native';
import { scaleSequential } from 'd3-scale';
import { transparentize } from 'polished';
import ChartStyles from './chartStyles';
import Legend from './Legend';
import { BlurView } from 'expo-blur';
import { Divider, IconButton, Tooltip } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import PieChart from './victory/PieChart';

interface PieChartCompProps {
  data: Array<any>;
  colorPallate: any;
  ChartTitle: string;
  subTitle?: string;
}

export default function PieChartComp(props: PieChartCompProps) {
  const [showFull, setShowFull] = useState<boolean>(false);
  const screenWidth = Dimensions.get('window').width;
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handlePress = (event: unknown, index: number) => {
    setSelectedValue(props.data[index].name);
  };

  const legendConfig = {
    legendOffset: 16,
    legendPosition: 'bottom',
  };

  const chartConfig = {
    backgroundGradientFrom: '#fff',
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: '#fff',
    backgroundGradientToOpacity: 0.5,
    fillShadowGradient: '#DF5353',
    fillShadowGradientOpacity: 1,
    color: (opacity = 1) => `#023047`,
    backgroundColor: '#1cc910',
    strokeWidth: 2,
    barPercentage: 0.5,
    useShadowColorFromDataset: false,
    style: {
      borderRadius: 8,
      marginBottom: 16,
      backgroundColor: transparentize(0.9, '#b2b5b1'),
    },
  };

  const onChartLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };
  const pieData = props.data && props.data.map((item: any) => ({
    name: item.name,
    value: Math.round(item.value.toFixed(2)),//Math.round(item.value).toFixed(2),
    color: item.color,
  }));
  
  return (
    <View>
      <View style={[ChartStyles.style]}>
        {props.data === undefined || props.data.length === 0 ? (
          <View>
            <Text style={ChartStyles.chartTitle}>{`There is no data available to show ${props.ChartTitle}`}</Text>
          </View>
        ) : (
          <View>
            <View style={[ChartStyles.container, Platform.OS==='web'?ChartStyles.box2:null]} onLayout={onChartLayout}>
              <View style={{ alignItems: 'center', flexDirection: 'row', width: containerWidth, justifyContent: 'center' }}>
                <View>
                  <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                    <IconButton icon="chart-pie" iconColor={'#ffffff'} size={30} />
                  </TouchableOpacity>
                </View>
                <View style={ChartStyles.chartTitleContainer}>
                  <View>
                    <Text style={[ChartStyles.chartTitle]}>{props.ChartTitle}</Text>
                  </View>
                  <View>
                    <Text style={[ChartStyles.chartSubTitle]}>{props.subTitle}</Text>
                  </View>
                </View>
                {Platform.OS === 'web' ? (
                  <View style={ChartStyles.fullScreenIconContainer}>
                    <Tooltip title="View in Full Screen">
                      <IconButton icon="fullscreen" iconColor={ChartStyles.chartTitle.color} size={20} onPress={() => setShowFull(true)} />
                    </Tooltip>
                  </View>
                ) : null}
              </View>
              <Divider />
              <View style={{ height: 300, justifyContent: 'flex-start' }}>
                <PieChart input={pieData && pieData.filter((x) => x.name !== 'null')} donutRadius={50} />
              </View>
              <View style={{ width: containerWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                  {pieData &&
                    pieData
                      .filter((x) => x.name !== 'null')
                      .map((item: any, index: number) => {
                        const name = item.name;
                        const color = item.color;
                        return <View key={index}><Legend fontColor={ChartStyles.chartTitle.color} key={name} name={name} color={color} /></View>;
                      })}
                </ScrollView>
              </View>
            </View>
          </View>
        )}
      </View>
      {showFull === false ? null : (
        <View style={Platform.OS === 'web' ? {} : { maxWidth: screenWidth, minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%' }}>
          <ScrollView>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
              <Modal animationType="fade" transparent={true} visible={showFull} onRequestClose={() => setShowFull(!showFull)}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
                  <BlurView intensity={80} style={{ flex: 1, padding: 10, justifyContent: 'center' }}>
                    <View style={{ marginLeft: '90%' }}>
                      <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                        <Tooltip title="Close">
                          <IconButton
                            icon="close-circle-outline"
                            iconColor={'#ffff'}
                            size={30}
                            onPress={() => setShowFull(!showFull)}
                          />
                        </Tooltip>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: screenWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                      <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                        {pieData &&
                          pieData
                            .filter((x) => x.name !== 'null')
                            .map((item: any, index: number) => {
                              const name = item.name;
                              const color = item.color;
                              return <View key={index}><Legend fontColor={ChartStyles.chartTitle.color} key={name} name={name} color={color} /></View>;
                            })}
                      </ScrollView>
                    </View>
                    <ScrollView horizontal contentContainerStyle={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                      <PieChart input={pieData && pieData.filter((x) => x.name !== 'null')} donutRadius={50} />
                    </ScrollView>
                  </BlurView>
                </View>
              </Modal>
            </View>
          </ScrollView>
        </View>
      )}
    </View>
  );
}
