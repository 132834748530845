import * as React from 'react';
import { Avatar } from 'react-native-paper';
import { View, StyleSheet, Pressable } from 'react-native';
import AvatarMenu from './AvatarMenu';
import { generateInitials } from '../../services/Utils';

export interface IUserAvatar{
    displayName:string
}

const UserAvatar: React.FC<IUserAvatar> = (props) => {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);


    return (
        <View>
            <Avatar.Text style={{backgroundColor:'#102B27'}} size={40} label={generateInitials(props.displayName)} />
        </View>
    );
};

const styles = StyleSheet.create({
    avatarContainer: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
});;


export default UserAvatar;