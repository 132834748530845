import * as React from 'react';
import { Menu, Divider, Text } from 'react-native-paper';
import { StyleSheet, Pressable, View } from 'react-native';
import UserAvatar from './UserAvatar';
import UserDetails from '../Unused/UserDetails';
import { useAuth } from '../../providers/AuthProvider';
import ConfirmBox from '../Common/ConfirmBox';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParams } from '../Home';
import { useClient } from '../../providers/ClientProvider';
import { useNav } from '../../providers/NavProvider';


// export interface IAvatarMenu {
//     open: boolean;
// }

const AvatarMenu: React.FC = ({ }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { sitesResp, setCurrentLeafObj, currentLeafObj,setSitesResp } = useNav();
    const { setClient, arrclientObj } = useClient();

    const [visible, setVisible] = React.useState(false);
    const { userDisplayName, userEmail, logout } = useAuth();
    const [showLogoutConfirm, setShowLogOutConfirm] = React.useState(false);
    const [showSwitchClientConfirm, setshowSwitchClientConfirm] = React.useState(false);
    const [showSwitchClient, setshowSwitchClient] = React.useState(false);

    const startTimeRef = React.useRef(0);


    React.useEffect(() => {

        if (arrclientObj && arrclientObj.length > 1 && sitesResp && currentLeafObj) {
            setshowSwitchClient(true);
        }
        else {
            setshowSwitchClient(false);
        }


    }, [currentLeafObj]);



    const openMenu = () => {
        setVisible(true);
        startTimeRef.current = Date.now();
    }

    const closeMenu = () => {
        setVisible(false);
        startTimeRef.current = 0;
    }

    const onDismiss = () => {
        if (calculateTimeElapsed() > 500) {
            closeMenu();
        }
    }
    const calculateTimeElapsed = () => {
        if (startTimeRef.current === 0) return 0;
        const endTime = Date.now()
        return endTime - startTimeRef.current;
    };

    const logOutPressed = () => {
        closeMenu();
        setShowLogOutConfirm(true);
    }
    const logoutCancelled = () => {
        setShowLogOutConfirm(false);

    }

    const switchClientPressed = () => {
        closeMenu();
        setshowSwitchClientConfirm(true);
    }

    const switchClientConfirmed = () => {
        setClient(null);
        setCurrentLeafObj(null);
        setSitesResp(null);
        setshowSwitchClientConfirm(false);
        navigation.navigate('Clients');
    }

    const logOutConfirmed = () => {
        setShowLogOutConfirm(false);
        logout();
    }
    const switchClientCancelled = () => {
        setshowSwitchClientConfirm(false);
    }

    return (
        <>
            <Menu
                visible={visible}
                onDismiss={onDismiss}
                anchorPosition='bottom'
                anchor={<Pressable onPress={openMenu} ><UserAvatar displayName={userDisplayName} /></Pressable>} >
                <View style={{ padding: 20 }}>
                    <Text>{userDisplayName}</Text>
                </View>
                <Divider />

                {showSwitchClient && <Menu.Item leadingIcon="account-switch-outline" onPress={switchClientPressed} title="Switch Client" />}
                <Menu.Item leadingIcon="logout" onPress={logOutPressed} title="Logout" />
                {/* <Divider />
            <Menu.Item onPress={() => { }} title="Item 3" /> */}
            </Menu>
            {showLogoutConfirm && <ConfirmBox title="Logout" message='You will be logged out of Greenport. Are you sure?' yesCallback={logOutConfirmed} noCallback={logoutCancelled}></ConfirmBox>}
            {showSwitchClientConfirm && <ConfirmBox title="Switch Client" message='Are you sure?' yesCallback={switchClientConfirmed} noCallback={switchClientCancelled}></ConfirmBox>}
        </>
    );
};

const styles = StyleSheet.create({

});


export default AvatarMenu;