import React from 'react';
import { View, StyleSheet,ScrollView } from 'react-native';
import {Text} from 'react-native-paper';
import { useClient } from '../providers/ClientProvider';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParams } from './Home';
import { useNav } from '../providers/NavProvider';
import { getObjectByPath } from '../services/Utils';
import { ISitee } from './ClientLanding';
import { useFocusEffect } from '@react-navigation/native';
import Header from './Header';
import ReportProvider from './Logs/ReportProvider';
import { LinearGradient } from 'expo-linear-gradient';
//import { ScrollView } from 'react-native-gesture-handler';

type Props = NativeStackScreenProps<RootStackParams, "List">

const ReportLanding: React.FC<Props> = ({ route }) => {
    const { setCurrentClientShortURL, clientObj } = useClient();
    //const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { sitesResp, setCurrentLeafObj, currentLeafObj } = useNav();
    const { clientName } = useClient();
    const [siteObj, setSiteObj] = React.useState<ISitee>(null);

    useFocusEffect(() => {
        if (siteObj) {
            setCurrentLeafObj(siteObj);
        }
    });

    React.useEffect(() => {
        setCurrentClientShortURL(route.params.clientShortURL);
        if (sitesResp) {
            const tempsiteObj = getObjectByPath(decodeURIComponent(route.params.sitePath), sitesResp);
            // setCurrentLeafObj(tempsiteObj);
            setSiteObj(tempsiteObj);

        }
    }, [route && route.params && route.params.sitePath && route.params.sitePath, sitesResp]);
    return (
        <View>
            <View>
                <Header></Header>
            </View>
            {/* <ScrollView contentContainerStyle={{ flexGrow: 1 }}> */}
                <View style={styles.navWrapper}>
                {(siteObj && siteObj.title) &&
                        <View style={{ backgroundColor: '#004876', width: "100%", padding: 10, alignItems: 'center' }}>
                            <Text variant='titleLarge' style={{ color: '#fff' }} >{siteObj.title}</Text>
                        </View>
                    }
                    <View style={styles.container}>
                        {(siteObj) &&
                            // <LinearGradient colors={['#00c4b3', '#02786e']} >
                            //  <LinearGradient colors={['#004876', '#676767', '#FFFFFF']} >
                            <LinearGradient colors={['#e9f9ef', '#FFF', '#FFF']} >
                            {/* <LinearGradient colors={['#185760', '#30b0c2']} > */}
                                <View>
                                    {
                                        // siteObj.path.toLowerCase().includes('consolidatedlogs')
                                        //     ?
                                        //     <ReportProvider logTitle={siteObj.title} parentId={siteObj.parentId} />
                                        //     :
                                        //     null
                                        <ReportProvider logTitle={siteObj.title} parentId={siteObj.parentId} />
                                    }
                                </View>
                            </LinearGradient>
                        }
                    </View>
                </View>
            {/* </ScrollView> */}
        </View>
    )
}

const styles = StyleSheet.create({
    navWrapper: {},
    container: {
        //flex: 1,
        //padding: 16,
        // gap: 20,
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        //alignItems: 'center',
        // justifyContent: 'space-evenly',
        //paddingLeft: 10,
        //paddingRight: 10,
        //minHeight:650,
        //maxWidth:1300,
        //alignSelf:'center'
    },
    // reportContainer: {
    //     alignItems: 'center'
    // },
    // card: {
    //     minWidth: 300,
    //     marginVertical: 8
    // },
    // logTitle: {
    //     fontWeight: '900',
    //     color:'#FFFFFF',//#ffcf1a'
    // },
});

export default ReportLanding;