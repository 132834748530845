import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParams } from '../Home';
import { useNav } from '../../providers/NavProvider';
import { useClient } from '../../providers/ClientProvider';
import { getObjectByPath } from '../../services/Utils';

type Props = NativeStackScreenProps<RootStackParams, "NotFoundSite">


const NotFound: React.FC<Props> = ({route}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { sitesResp, setCurrentLeafObj } = useNav();
    const { setCurrentClientShortURL, clientObj } = useClient();
    
    React.useEffect(() => {
        setCurrentClientShortURL(route.params && route.params.clientShortURL && route.params.clientShortURL);        
      }, []);
    const onPress = () => {

        if(route.params && route.params.clientShortURL && route.params.clientShortURL)
        {
            navigation.replace("Site",{sitePath:sitesResp.path, clientShortURL:route.params.clientShortURL});
        }
       else
        {
            navigation.replace("Clients");
        }
        
    }


    return (

        <View style={styles.container}>
            <Text variant='displaySmall'>The link you followed doesn't work anymore!</Text>
            <TouchableOpacity onPress={onPress}>
                <View style={styles.subContainer} ><IconButton icon="home-import-outline" size={100} ></IconButton>
                    <Text>Go To Home</Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        //flexWrap:'wrap',
        gap: 20,
        flex: 1,
        padding: 100,
    },
    subContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        //flexWrap:'wrap',
        //gap: 20,
        flex: 1,
        padding: 100,
    }
});

export default NotFound;