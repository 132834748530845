import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import { Loader } from '../../Common/Loader';
import { colorType3, colorType5, logStyles } from './LogStyles';
import LogFilter from './LogFilterDropdown';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import LogCountCard from './LogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function SpoilLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [spoilLogData, setSpoilLogData] = useState<null | any>(null);
  const [spoilLogColumns, setSpoilLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {

        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setSpoilLogData(json.items);
        setSpoilLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return spoilLogData && spoilLogColumns ? <CreateLogCharts setSpoilLogData={setSpoilLogData} spoilLogData={spoilLogData} spoilLogColumns={spoilLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateLogChartsProps {
  spoilLogData: any;
  spoilLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setSpoilLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ setSpoilLogData, spoilLogData, spoilLogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = spoilLogColumns && [
    ...spoilLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const sortedData = [...spoilLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const Reused: string = checkReused(item.SpoilDisposalMethod);
    return { ...item, Period, key, Date, Reused };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkReused(div: string) {
    switch (div) {
      case "Reuse on-site": return "Reuse";
      case "Reuse off-site": return "Reuse";
      case "Temporary storage for future reuse": return "Reuse";

      case "Disposal off-site": return "Not Reused";
      case "Landfill": return "Not Reused";
      case "Slurry lagoon": return "Not Reused";
      case "Other": return "Not Reused";
      default: return null
    }
  }
  function handleYearFilterSelect(val: string) {
    let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const uniqueDates = [...new Set(newData.map((item) => item.Date))];
  const filteredData = newData.filter(item => item.QuantityTonne !== null && item.QuantityTonne !== '');


  const uniqueDisposalMethods = [...new Set(filteredData.map(item => item.SpoilDisposalMethod))].filter(type => type !== null);
  const lineChartData: any = {};
  const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  };
  uniqueDisposalMethods.forEach((method) => {
    lineChartData[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueDisposalMethods.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.SpoilDisposalMethod === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.QuantityTonne) ? acc + item.QuantityTonne : acc, 0);
      lineChartData[method].push(sum);
    });
  });
  const colorScaleLine = scaleOrdinal(schemeCategory10);
  const datasets = Object.entries(lineChartData).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataByDisposal = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasets
  };
  const legendData2 = [...uniqueDisposalMethods].map((type: any, index: any) => ({
    name: type,
  }));



  const uniqueSpoilType = [...new Set(filteredData.map(item => item.SpoilType))].filter(type => type !== null);
  const lineChartDataByType: any = {};
  uniqueSpoilType.forEach((method) => {
    lineChartDataByType[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueSpoilType.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.SpoilType === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.QuantityTonne) ? acc + item.QuantityTonne : acc, 0);
      lineChartDataByType[method].push(sum);
    });
  });
  const colorScaleLineForType = scaleOrdinal(schemeCategory10);
  const datasetsByType = Object.entries(lineChartDataByType).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLineForType(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataByType = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasetsByType
  };
  const legendData1 = [...uniqueSpoilType].map((type: any, index: any) => ({
    name: type,
  }));




  const pieData: any = {};

  newData.forEach((item: any) => {
    const spoilType = item.SpoilType;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData[spoilType]) {
      pieData[spoilType] += quantityTonne;
    } else {
      pieData[spoilType] = quantityTonne;
    }
  });
  const colorScale1 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const bySpoilCategoryPieData = Object.keys(pieData).map((spoilType, index) => ({
    name: spoilType,
    value: pieData[spoilType],
    color: colorType5(index.toString()),//colorScale1(pieData[spoilType] / Math.max(...Object.values(pieData) as number[])),
  }));

  //

  const pieData2: any = {};
  newData.forEach((item: any) => {
    const disposalMethod = item.SpoilDisposalMethod;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData2[disposalMethod]) {
      pieData2[disposalMethod] += quantityTonne;
    } else {
      pieData2[disposalMethod] = quantityTonne;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const bySpoilDisposalMethodPieData = Object.keys(pieData2).map((spoilDestination, index) => ({
    name: spoilDestination,
    value: pieData2[spoilDestination],
    color: colorType3(index.toString()),//colorScale2(pieData2[spoilDestination] / Math.max(...Object.values(pieData2) as number[])),
  }));

  /** Start of Table 1 **/


  const spoilSpoilType = spoilLogColumns.find((col: any) => col.columnName === 'SpoilType')?.choiceChoices;
  const spoilType = spoilSpoilType.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable1 = newData.filter(item => item.DisposalMethod != '' || item.DisposalMethod != null).reduce((result, item) => {
    const { Period, SpoilType, QuantityTonne } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      spoilType.forEach((disposalMethod: string) => {
        result[Period][disposalMethod] = 0; // Initialize with 0 for each DisposalMethod
      });
    }
    result[Period][SpoilType] += Math.round(QuantityTonne);
    return result;
  }, {});
  interface GroupedDataItem {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable1Array: GroupedDataItem[] = Object.values(groupedDataTable1);
  const fData = groupedDataTable1Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    delete newItem.null;
    return newItem;
  });
  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const itemCount = filteredItems.length;
    const sumQuantityTonne = Math.round(filteredItems.reduce((sum, item) => sum + item.QuantityTonne, 0));
    const filteredItems1 = fData.filter(item => item.Period === Period);
    const result = [
      ["Period", filteredItems1[0]?.Period], // Assuming the filtered data has only one item with the desired period
      ["Number Of Samples", itemCount],
      ["Total", sumQuantityTonne],
    ];
    filteredItems1.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  // Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => {
    const sumByspoilType = Math.round(spoilType.reduce((sum: number, spoilType: string) => sum + values[spoilType], 0));
    return {
      ...values,
      "Total (By Disposal Method)": sumByspoilType
    };
  });
  const combinedData1: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });
  // Convert the combinedData object back to an array
  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /** End of Table 1 **/

  /** Start of Table 2 **/

  const SpoilMethodLabels = spoilLogColumns.find((col: any) => col.columnName === 'SpoilDisposalMethod')?.choiceChoices;
  const spoilDisposalMethodTable = SpoilMethodLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable2 = newData.filter(item => item.DisposalMethod != '' || item.DisposalMethod != null).reduce((result, item) => {
    const { Period, SpoilDisposalMethod, QuantityTonne } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      spoilDisposalMethodTable.forEach((disposalMethod: string) => {
        result[Period][disposalMethod] = 0; // Initialize with 0 for each DisposalMethod
      });
    }
    result[Period][SpoilDisposalMethod] += Math.round(QuantityTonne);
    return result;
  }, {});
  interface GroupedDataItem2 {
    Period: any;
    [key: string]: number | null;
  }
  const groupedDataTable2Array: GroupedDataItem2[] = Object.values(groupedDataTable2);
  const fData2 = groupedDataTable2Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });
  const filteredDataByPeriod2 = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const itemCount = filteredItems.length;
    const sumQuantityTonne = Math.round(filteredItems.reduce((sum, item) => sum + item.QuantityTonne, 0));
    const sumNotReusedQuantityTonne: number = Math.round(filteredItems.filter(item => item.Reused === "Not Reused").reduce((sum: number, item) => sum + item.QuantityTonne, 0));
    const sumReusedQuantityTonne: number = Math.round(filteredItems.filter(item => item.Reused === "Reuse").reduce((sum: number, item) => sum + item.QuantityTonne, 0));
    const filteredItems1 = fData2.filter(item => item.Period === Period);
    //const TotalPercentage:number = (((sumReusedQuantityTonne + sumNotReusedQuantityTonne)/itemCount) * 100).toFixed(0);
    const result = [
      ["Period", filteredItems1[0]?.Period],
      ["Number Of Samples", itemCount],
      ["Total", sumQuantityTonne],
      ["Total Not Reused (Tonnes)", sumNotReusedQuantityTonne],
      ["Total Used / Potemtial Reuse (Tonnes)", sumReusedQuantityTonne]
      //["Percentage", TotalPercentage],
    ];
    filteredItems1.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });
    return Object.fromEntries(result);
  });

  // Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
  const transformedDataTable2 = Object.values(filteredDataByPeriod2).map((values: any) => {
    const sumByspoilDisposalMethodTable = spoilDisposalMethodTable.reduce((sum: number, spoilDisposalMethodTable: string) => sum + values[spoilDisposalMethodTable], 0);
    return {
      ...values,
      "Total (By Disposal Method)": sumByspoilDisposalMethodTable
    };
  });
  const combinedData2: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  filteredDataByPeriod2.forEach(item => {
    const { Period, ...rest } = item;
    combinedData2[Period] = { ...combinedData2[Period], ...rest };
  });

  // Convert the combinedData object back to an array
  const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /** End of Table 2 **/



  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a: any, b: any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]
    const TotalCount = newData.reduce((total, item) => {
      if (item.QuantityTonne) {
        return total + item.QuantityTonne;
      }
      return total;
    }, 0);

  return (
    <View style={logStyles.logRoot}>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
              <LogCountCard cardTitle={'Total Spoil Quantities (Tonnes)'} number={Math.round(TotalCount)}/>
                <View style={[logStyles.card, { justifyContent: 'center' }]}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={bySpoilCategoryPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Spoil Quantities/Type"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataByType} label={uniquePeriod} subTitle={'(Monthly - m3)'} ChartTitle={"Spoil Quantities/Type"} /></View>
                
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={bySpoilDisposalMethodPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Spoil Qty /(Destination)"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineDataByDisposal} label={uniquePeriod} subTitle={'(Monthly - Tonne)'} ChartTitle={"Spoil Qty /Destination"} /></View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="Tables" icon="table" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
            <View style={[logStyles.container]} key={0}>
                <View style={logStyles.cardTwoFilter}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardTwo}>
                    {mergedData1 && columnKeys1 && mergedData1.length > 0 ? (
                      <CustomDT
                        data={mergedData1}
                        columns={columnKeys1}
                        siteId={siteId}
                        logTitle={logTitle}
                      />
                    ) : null
                    }
                  </View>
                  <View style={logStyles.cardTwo}>
                    {mergedData2 && columnKeys2 && mergedData2.length > 0 ? (
                      <CustomDT
                        data={mergedData2}
                        columns={columnKeys2}
                        siteId={siteId}
                        logTitle={logTitle}
                      />
                    ) : null
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
            <View style={[logStyles.container]} key={0}>
                <View style={logStyles.cardTwoFilter}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardTwo}>
                    {
                      setNewData &&
                      newData &&
                      uniqueYearsArray &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={false}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};

