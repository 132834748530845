import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox } from 'react-native';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import { Loader } from '../../Common/Loader';
import { logStyles } from './LogStyles';
import LogFilter from './LogFilterDropdown';
interface Props {
  siteId:string;
  logTitle:string;
}
export default function MonthlyWasteLog(props:Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
    const [wasteLogData, setWasteLogData] = useState<null | any>(null);
  const [wasteLogColumns, setWasteLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try 
      {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setWasteLogData(json.items);
        setWasteLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return wasteLogData && wasteLogColumns ? <CreateLogCharts setWasteLogData={setWasteLogData} wasteLogData={wasteLogData} wasteLogColumns={wasteLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateLogChartsProps {
  wasteLogData: any;
  wasteLogColumns:any;
  siteId:string;
  logTitle:string;
  clientAPIURL:string;
  setWasteLogData:any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({setWasteLogData, wasteLogData,wasteLogColumns, siteId, logTitle, clientAPIURL }) => {
  
  const sortedData = [...wasteLogData].sort((a:any, b:any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {  
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const Hazardous:string = checkHazardous(item.WasteType);
    const Diverted:string = checkDiverted(item.WasteDisposalMethod);
    return { ...item, Period, key, Date, Hazardous, Diverted };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value:Date){
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function GetPeriod(value: Date){
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkDiverted(div:string){
    //let val;
    switch(div){
      case "Reuse on-site":return "Diverted";
      case "Reuse off-site":return "Diverted";
      case "Recycling / Recover":return "Diverted";
      case "Landfill":return "Not Diverted";
      case "Dump site":return "Not Diverted";
      case "Other":return "Not Diverted";
      default: return null
    }
  }

  function checkHazardous(wT:string){
    //let val;
    switch(wT){
      case "Paper/ Cardboard":return "Non Hazardous";
      case "Plastic":return "Non Hazardous";
      case "Metal":return "Non Hazardous";
      case "Wood":return "Non Hazardous";
      case "Green":return "Non Hazardous";
      case "Concrete/ Inert debris":return "Non Hazardous";
      case "Asphalt":return "Non Hazardous";
      case "General Domestic/ Food waste":return "Non Hazardous";
      case "General Construction/ Mixed Waste":return "Non Hazardous";
      case "Electronic (without hazardous materials)":return "Non Hazardous";
      case "Other (non hazardous)":return "Non Hazardous";

      case "Electronic (containing hazardous materials)":return "Hazardous";
      case "Used oil":return "Hazardous";
      case "Used oil filters":return "Hazardous";
      case "Contaminated material (including contaminated pads, rags, gloves and soil)":return "Hazardous";
      case "Batteries":return "Hazardous";
      case "Medical":return "Hazardous";
      case "Other (hazardous)":return "Hazardous";
      default: return null
    }
  }
  function handleYearFilterSelect(val: string) {
        let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item:any) => item.Year))];
  /** Start of Table 1 **/
const wasteWaterDisposalMethodLabels = wasteLogColumns.find((col: any) => col.columnName === 'WasteDisposalMethod')?.choiceChoices;
const wasteDisposalMethodTable = wasteWaterDisposalMethodLabels.split("#;#").filter((value: string) => value !== "undefined");
const groupedDataTable1 = newData.filter(item => item.DisposalMethod != '' || item.DisposalMethod != null).reduce((result, item) => {
  const { Period, DisposalMethod, QuantityTonne } = item;
  if (!result[Period]) {
    result[Period] = { Period };
    wasteDisposalMethodTable.forEach((disposalMethod:string) => {
      result[Period][disposalMethod] = 0; // Initialize with 0 for each DisposalMethod
    });
  }
  result[Period][DisposalMethod] += Math.round(QuantityTonne);
  return result;
}, {});

interface GroupedDataItem {
  Period: any;
  [key: string]: number | null;
}

const groupedDataTable1Array: GroupedDataItem[] = Object.values(groupedDataTable1);
const fData = groupedDataTable1Array.map(item => {
  const newItem = { ...item };
  delete newItem.undefined;
  return newItem;
});

const periods = [...new Set(newData.map(oItem => oItem.Period))];
const filteredDataByPeriod = periods.map(Period => {
  const filteredItems = newData.filter(item => item.Period === Period);
  const TotalHaz = filteredItems.filter(item => item.WasteCategory === "Hazardous");
  const TotalNonHaz = filteredItems.filter(item => item.WasteCategory === "Non Hazardous");
  const itemCount = filteredItems.filter(item => item.WasteCategory === "Hazardous" || item.WasteCategory === "Non Hazardous").length;//parseFloat(TotalHaz.length) + parseFloat(TotalNonHaz.length);
  const sumQuantityTonne = filteredItems.reduce((sum, item) => sum + item.QuantityTonne, 0);
  const sumHazardousQuantityTonne = TotalHaz.reduce((sum, item) => sum + item.QuantityTonne, 0);
  const sumNonHazardousQuantityTonne = TotalNonHaz.reduce((sum, item) => sum + item.QuantityTonne, 0);
  const sumNotDivertedQuantityTonne = filteredItems.filter(item => item.Diverted === "Not Diverted").reduce((sum, item) => sum + item.QuantityTonne, 0);
  const sumDivertedQuantityTonne = filteredItems.filter(item => item.Diverted === "Diverted").reduce((sum, item) => sum + item.QuantityTonne, 0);
  const filteredItems1 = fData.filter(item => item.Period === Period);
  let TotalPercentage = (((sumDivertedQuantityTonne) / (sumDivertedQuantityTonne + sumNotDivertedQuantityTonne)) * 100).toFixed(0);

  const result = [
    ["Period", filteredItems1[0]?.Period],
    //["Number Of Samples", itemCount],
    //["Total", sumQuantityTonne],
    ["Hazardous", sumHazardousQuantityTonne],
    ["Non Hazardous", sumNonHazardousQuantityTonne],
    //["Total Not Diverted (Tonnes)", sumNotDivertedQuantityTonne],
    //["Total Diverted (Tonnes)", sumDivertedQuantityTonne],
    //["Percentage", TotalPercentage],
  ];
  
  filteredItems1.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (key !== "Period") {
        result.push([key, value]);
      }
    });
  });
  return Object.fromEntries(result);
});


// Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values:any) => {
const sumBywasteDisposalMethodTable = wasteDisposalMethodTable.reduce((sum:number, wasteDisposalMethodTable:string) => sum + values[wasteDisposalMethodTable], 0);
return {
  ...values,
  "Total (By Disposal Method)": sumBywasteDisposalMethodTable
};
});

const combinedData1: { [key: string]: { [key: string]: number } }  = {};
// Merge datasets based on Period
filteredDataByPeriod.forEach(item => {
const { Period, ...rest } = item;
combinedData1[Period] = { ...combinedData1[Period], ...rest };
});

// Convert the combinedData object back to an array
const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
Period,
...values
}));
const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
return {
  ColumnName: key,
  Order: index,
};
}) : [];

/** End of Table 1 **/





/** Start of Table 2 **/

const wasteTypeLabels = wasteLogColumns.find((col:any) => col.columnName === 'WasteType')?.choiceChoices;
 // choices below hard coded.
//const NonHazardData = newData.filter((a) => a.Diverted == 'Diverted');
const choices = "Paper/ Cardboard#;#Plastic#;#Metal#;#Wood#;#Green#;#Concrete/ Inert debris#;#Asphalt#;#General Domestic/ Food waste#;#General Construction/ Mixed Waste#;#Electronic (without hazardous materials)#;#Electronic (containing hazardous materials)#;#Used oil#;#Used oil filters#;#Contaminated material (including contaminated pads#;#rags#;#gloves and soil)#;#Batteries#;#Medical#;#Other (hazardous)#;#Other (non hazardous)";

    const wasteTypesTable = choices.split("#;#").reduce((filteredValues, value) => {
      let isHaz = checkHazardous(value);
    
      if (isHaz === "Non Hazardous") {
        filteredValues.push(value);
      }
    
      return filteredValues;
    }, []);

const groupedDataTable = newData.reduce((result, item) => {
  const { Period, WasteType, QuantityTonne } = item;
  if (!result[Period]) {
    result[Period] = { Period };
    wasteTypesTable.forEach((wasteType:any) => {
      result[Period][wasteType] = 0; // Initialize with 0 for each WastewaterType
    });
  }
  result[Period][WasteType] += Math.round(QuantityTonne);
  return result;
}, {});

// Convert the grouped data object into an array and calculate the sum for each WastewaterType within each Period
const transformedDataTable = Object.values(groupedDataTable).map((values:any) => {
  const sumBywasteType = wasteTypesTable.reduce((sum:number, wasteType:string) => sum + values[wasteType], 0);
  return {
    ...values,
    //"Total (By Waste Type)": sumBywasteType
  };
});
const combinedData: { [key: string]: { [key: string]: number } }  = {};
// Merge datasets based on Period
transformedDataTable.forEach(item => {
  const { Period, ...rest } = item;
  combinedData[Period] = { ...combinedData[Period], ...rest };
});
// Convert the combinedData object back to an array
const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
  Period,
  ...values
}));
const columnKeys = mergedData.length > 0 ? Object.keys(mergedData[0]).map((key, index) => {
  return {
    ColumnName: key,
    Order: index,
  };
}) : [];

/** End of Table 2 **/

/** Start of Table 3 **/

  const wasteTypesTableHazardous = choices.split("#;#").reduce((filteredValues, value) => {
    let isHaz = checkHazardous(value);
  
    if (isHaz === "Hazardous") {
      filteredValues.push(value);
    }
  
    return filteredValues;
  }, []);

const groupedDataTable2 = newData.reduce((result, item) => {
  const { Period, WasteType, QuantityTonne } = item;
  if (!result[Period]) {
    result[Period] = { Period };
    wasteTypesTableHazardous.forEach((wasteTypeHazard:any) => {
      result[Period][wasteTypeHazard] = 0; // Initialize with 0 for each DisposalMethod
    });
  }
  result[Period][WasteType] += Math.round(QuantityTonne);
  return result;
}, {});

// Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
const transformedDataTable2 = Object.values(groupedDataTable2).map((values:any) => {
  const sumBywasteTypeHazard = wasteTypesTableHazardous.reduce((sum:number, wasteTypeHazard:string) => sum + values[wasteTypeHazard], 0);
  return {
    ...values,
    "Total (By Waste Type)": sumBywasteTypeHazard
  };
});
const combinedData2: { [key: string]: { [key: string]: number } }  = {};
// Merge datasets based on Period
transformedDataTable2.forEach(item => {
  const { Period, ...rest } = item;
  combinedData2[Period] = { ...combinedData2[Period], ...rest };
});
// Convert the combinedData object back to an array
const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
  Period,
  ...values
}));
const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
  return {
    ColumnName: key,
    Order: index,
  };
}) : [];

/** End of Table 3 **/


/**
 * Consolidated Monthly Waste Log
 */
const finalData = mergedData.map((item) => {
  const matchingItem = mergedData1.find((data) => data.Period === item.Period);
  return { ...item, ...matchingItem };
});
const finalColumnKeys = [
  ...columnKeys1, // Exclude 'Period' column from the second table
  ...columnKeys.filter((key) => key.ColumnName !== 'Period'), // Exclude 'Period' column from the first table
];

  
  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a:any, b:any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]

  return (
    <View style={logStyles.logRoot}>
       <ScrollView >
    <View style={logStyles.container}>
      {/* <VictoryCharts /> */}
      <View style={logStyles.cardTwo}>
      <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
    </View>
    </View>
      <View>
        {/* <View style={[]}>
          {mergedData1 && columnKeys1 && (
              <CustomDT
                data={mergedData1}
                columns={columnKeys1}
                siteId={siteId}
                logTitle={logTitle}
              />
            )
          }
        </View>
        <View style={[]}>
          {mergedData && columnKeys && (
              <CustomDT
                data={mergedData}
                columns={columnKeys}
                siteId={siteId}
                logTitle={logTitle}
              />
            )
          }
          </View> */}
          <View style={logStyles.container}>
        <View style={logStyles.cardTwo}>
          {finalData && finalColumnKeys && (
              <CustomDT
                data={finalData}
                columns={finalColumnKeys}
                siteId={siteId}
                logTitle={logTitle}
              />
            )
          }
          </View>
          </View>
      </View>
      </ScrollView>
    </View>
  );
};