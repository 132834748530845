import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, Dimensions, Modal, ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import CustomDT from '../common/controls/customDataTable';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import ToastMessage from '../common/controls/Toast';
import ButtonControl from '../common/controls/Button';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters } from './ReUsableComps';
import { BlurView } from 'expo-blur';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import formCustomStyles from '../common/forms/formStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ConsolHazardousMaterial(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return LogData && LogColumns ? <CreateNoiseLogCharts setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : null;
}
interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setLogData, LogData, LogColumns, siteId, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();

  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  const sortedData = [...LogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const maxLimit: number = Math.max(
      parseFloat(item.MonitoringResults1),
      parseFloat(item.MonitoringResults2),
      parseFloat(item.MonitoringResults3)
    );

    //const Conformity = maxLimit >= NoiseLevel ? 'Conformity' : 'Non Conformity';
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, maxLimit, key, Date };
  });
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }

  /**
   * Start Constructing data for Pie Chart by Material Type
   */
  const pieData: any = {};
  updatedData.forEach((item: any) => {
    const materialType = item.MaterialType;
    const quantitym3 = item.StoredQuantityLiters || 0;

    if (pieData[materialType]) {
      pieData[materialType] += quantitym3;
    } else {
      pieData[materialType] = quantitym3;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const bymaterialTypePieData = Object.keys(pieData).map((materialType, index) => ({
    name: materialType,
    value: pieData[materialType],
    color: colorType5(index.toString()),//colorScale(pieData[materialType] / Math.max(...Object.values(pieData) as number[])),
  }));


  /**
   * end Constructing data for Pie Chart by Material Type
   * Start constructig data for lineChart 1
   */

  const materialTypes = new Set();

  // Group the data by period and Material type
  const groupedData = updatedData.reduce((result: any, item: any) => {
    const period = item.Period;
    const materialType = item.MaterialType;
    const quantity = item.StoredQuantityLiters || 0;

    const key = period + '_' + materialType;
    if (result[key]) {
      result[key] += quantity;
    } else {
      result[key] = quantity;
    }

    materialTypes.add(materialType);

    return result;
  }, {});

  // Generate the line chart data for each Material type

  const colorScale1 = scaleOrdinal(schemeCategory10);

  const lineData = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: [],
  };

  // Generate the line chart data for each Material type
  materialTypes.forEach((materialType: any, index: any) => {
    const dataset = {
      data: [],
      color: (opacity = 1) => colorScale1(index) + Math.round(opacity * 255).toString(16),
      strokeWidth: 2,
    };

    Object.entries(groupedData).forEach(([key, value]) => {
      const [period, ft] = key.split('_');
      if (ft === materialType) {
        dataset.data.push(value);
      }
    });

    lineData.datasets.push(dataset);
  });

  const legendData1 = [...materialTypes].map((type: any, index: any) => ({
    name: type,
  }));
  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }
  // function handleYearFilterSelect(val: string) {

  //   let filteredRows;
  //   if (val === 'ALL') {
  //     filteredRows = updatedData; // No filter applied
  //   } else {
  //     filteredRows = updatedData.filter((item: any) => item.Year === val);
  //   }
  //   setNewData(filteredRows);
  //   setSelectedYear(val);
  // }
  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
        //setLogData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];

  const materialTypeLabels = LogColumns.find((col: any) => col.columnName === 'MaterialType')?.choiceChoices;
  const materialTypesTable = materialTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });

  const groupedDataTable = newData.reduce((result, item) => {
    const { Period, MaterialType, StoredQuantityLiters } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      materialTypesTable.forEach((materialType: any) => {
        result[Period][materialType] = 0; // Initialize with 0 for each MaterialType
      });
    }
    result[Period][MaterialType] += Math.round(StoredQuantityLiters);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each MaterialType within each Period
  const transformedDataTable = Object.values(groupedDataTable).map((values: any) => {
    const sumBymaterialType = materialTypesTable.reduce((sum: number, materialType: string) => sum + values[materialType], 0);
    return {
      ...values,
      "Total (Material Type)": sumBymaterialType
    };
  });

  const combinedData: { [key: string]: { [key: string]: number } } = {};

  // Merge datasets based on Period
  transformedDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });



  // Convert the combinedData object back to an array
  const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
    Period,
    ...values
  }));

  const columnKeys = mergedData.length > 0 ? Object.keys(mergedData[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = newData.reduce((total, item) => {
    if (item.StoredQuantityLiters) {
      return total + item.StoredQuantityLiters;
    }
    return total;
  }, 0);
  return (
    <View style={logStyles.logRoot}>
      {/* <View style={[CommonStyles.container, { height: 100 }]} key={0}>
        <View style={CommonStyles.filterDropdown}>
          <SingleSelectDropdown
            options={yearFilterOptions}
            label={"Filter by Year"}
            onSelect={handleYearFilterSelect}
            selected={selectedYear}
          /></View>
        <View style={CommonStyles.filterDropdown}>
          <SingleSelectDropdown
            options={monthFilterOptions}
            label={"Filter by Month"}
            onSelect={handleMonthFilterSelect}
            selected={selectedMonth}
          /></View>
        <View style={CommonStyles.filterDropdown}>
          <SingleSelectDropdown
            options={packageFilterOptions}
            label={"Filter by Package"}
            onSelect={handlePackageFilterSelect}
            selected={selectedPackage}
          /></View>
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.ResetExportButtons}>
            <ButtonControl icon="filter-remove" mode="contained"
              onPress={() => handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData)}
              Title='Reset' disabled={false} />
          </View>
          <View style={CommonStyles.ResetExportButtons}>
            {
              EnableExport() === true ?
                <ButtonControl icon="export-variant" mode="contained" onPress={() => handleExportClick(newData)} Title='Export' disabled={false} />
                : null
            }
          </View>
        </View>
      </View> */}
      <View style={CommonStyles.ribbonIconContainer}>
        <View style={CommonStyles.IconContainer}>
          <FilterIcon setFilterVisible={setFilterVisible} FilterVisible={FilterVisible} />
        </View>
        <View style={CommonStyles.IconContainer}>
          {
            EnableExport() === true ?
              <ExportIcon handleExportClick={handleExportClick} newData={newData} />
              : null
          }
        </View>
        <SelectedFilters selectedMonth={selectedMonth} selectedPackage={selectedPackage} selectedYear={selectedYear} />
      </View>
      <View style={[{justifyContent: 'center', alignItems:'center'}]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Hazardous Material Stored (Liters)'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={FilterVisible}
        onRequestClose={() => {
          setFilterVisible(!FilterVisible);
        }}>
        <View style={formCustomStyles.centeredView}>
          {/* <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
            <View>
              <Tooltip title="Close">
                <IconButton
                  style={{ marginLeft: screenWidth - 60 }} 
                  icon="close-circle-outline"
                  iconColor={MD3Colors.error0}
                  size={20}
                  onPress={() => setFilterVisible(!FilterVisible)}
                />
              </Tooltip>
            </View>

            <View style={[CommonStyles.container]} key={0}>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={yearFilterOptions}
                  label={"Filter by Year"}
                  onSelect={handleYearFilterSelect}
                  selected={selectedYear}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={monthFilterOptions}
                  label={"Filter by Month"}
                  onSelect={handleMonthFilterSelect}
                  selected={selectedMonth}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <SingleSelectDropdown
                  options={packageFilterOptions}
                  label={"Filter by Package"}
                  onSelect={handlePackageFilterSelect}
                  selected={selectedPackage}
                /></View>
              <View style={[CommonStyles.filterDropdown, CommonStyles.filterCard]}>
                <ButtonControl icon="filter-remove" mode="contained"
                  onPress={() => {handleFilterReset(setSelectedMonth, setSelectedYear, setSelectedPackage, setNewData, updatedData); setFilterVisible(false)}}
                  Title='Clear' disabled={false} />
              </View>
            </View>
          </BlurView> */}
          <FilterBox
            FilterVisible={FilterVisible}
            setFilterVisible={setFilterVisible}
            screenWidth={screenWidth}
            CommonStyles={CommonStyles}
            yearFilterOptions={yearFilterOptions}
            handleYearFilterSelect={handleYearFilterSelect}
            selectedYear={selectedYear}
            monthFilterOptions={monthFilterOptions}
            handleMonthFilterSelect={handleMonthFilterSelect}
            selectedMonth={selectedMonth}
            packageFilterOptions={packageFilterOptions}
            handlePackageFilterSelect={handlePackageFilterSelect}
            selectedPackage={selectedPackage}
            handleFilterReset={handleFilterReset}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            setSelectedPackage={setSelectedPackage}
            setNewData={setNewData}
            updatedData={updatedData}
          />
        </View>
      </Modal>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>
      <View style={logStyles.logRoot}>
        <TabsProvider defaultIndex={0}>
          <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
            <TabScreen label="Charts" icon="chart-bar" >
              <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                  <View style={[CommonStyles.container, CommonStyles.divider]}>
                    <View key={1} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={bymaterialTypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Material Qty(Ltr) / Type"} /></View>
                    <View key={2} style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Material Qty(Ltr) / Type"} /></View>
                  </View>
                </View>
              </ScrollView>
            </TabScreen>
            <TabScreen label="List" icon="database" >
              <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                  <View style={CommonStyles.container}>
                    <View style={CommonStyles.cardTwo}>
                      {
                        newData &&
                        newData.length > 0 &&
                        ColumnsWithIDColumn && (
                          <PaperTableDynamic
                            data={newData}
                            columns={ColumnsWithIDColumn}
                            siteId={siteId}
                            logTitle={logTitle}
                            updateData={handleDataUpdate}
                            isConsolidatedTable={true}
                          />
                        )
                      }
                    </View>
                  </View>
                </View>
              </ScrollView>
            </TabScreen>
          </Tabs>
        </TabsProvider>
      </View>
    </View>
  );
};
