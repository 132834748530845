import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, Dimensions, Modal, ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import { Loader } from '../../Common/Loader';
import ToastMessage from '../common/controls/Toast';
import ButtonControl from '../common/controls/Button';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { FilterIcon, ExportIcon, IconsContainer, FilterBox, SelectedFilters } from './ReUsableComps';
import { BlurView } from 'expo-blur';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import formCustomStyles from '../common/forms/formStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function ConsolEnergyLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  let showLoad: boolean = true;
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
        showLoad = false;
      } catch (error) {

      }
    }
    fetchData();
  }, []);
  return LogData === null || LogColumns === null ? <Loader />
    :
    LogData && LogColumns ? <CreateNoiseLogCharts setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : null;
}
interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setLogData, LogData, LogColumns, siteId, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const formColumns = {
    columns: ColumnsWithIDColumn.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
      }
    }).filter((column) => column !== null),
  };
  const sortedData = [...LogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    //const Conformity = maxLimit >= NoiseLevel ? 'Conformity' : 'Non Conformity';
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, key, Date };
  });
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  /**
   * Start Constructing data for Pie Chart by Fuel Type
   */
  const pieData: any = {};
  updatedData.forEach((item: any) => {
    const fuelType = item.FuelTypeEnergySource;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData[fuelType]) {
      pieData[fuelType] += quantitym3;
    } else {
      pieData[fuelType] = quantitym3;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byFuelTypePieData = Object.keys(pieData).map((fuelType, index) => ({
    name: fuelType,
    value: pieData[fuelType],
    color: colorType5(index.toString()),//colorScale(pieData[fuelType] / Math.max(...Object.values(pieData) as number[])),
  }));



  /**
   * end Constructing data for Pie Chart by Fuel Type
   * start Constructing data for Pie Chart by Use
   */
  const pieData2: any = {};
  updatedData.forEach((item: any) => {
    const Use = item.Use;
    const quantitym3 = item.Quantitym3 || 0;

    if (pieData2[Use]) {
      pieData2[Use] += quantitym3;
    } else {
      pieData2[Use] = quantitym3;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byUsePieData = Object.keys(pieData2).map((use, index) => ({
    name: use,
    value: pieData2[use],
    color: colorType3(index.toString()),//colorScale2(pieData2[use] / Math.max(...Object.values(pieData2) as number[])),
  }));


  /**
   * end Constructing data for Pie Chart by Fuel Type
   * Start constructig data for lineChart 1
   */

  const fuelTypes = new Set();

  // Group the data by period and fuel type
  const groupedData = updatedData.reduce((result: any, item: any) => {
    const period = item.Period;
    const fuelType = item.FuelTypeEnergySource;
    const quantity = item.Quantitym3 || 0;

    const key = period + '_' + fuelType;
    if (result[key]) {
      result[key] += quantity;
    } else {
      result[key] = quantity;
    }

    fuelTypes.add(fuelType);

    return result;
  }, {});

  // Generate the line chart data for each fuel type

  const colorScale1 = scaleOrdinal(schemeCategory10);

  const lineData = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: [],
  };

  // Generate the line chart data for each fuel type
  fuelTypes.forEach((fuelType: any, index: any) => {
    const dataset = {
      data: [],
      color: (opacity = 1) => colorScale1(index) + Math.round(opacity * 255).toString(16),
      strokeWidth: 2,
    };

    Object.entries(groupedData).forEach(([key, value]) => {
      const [period, ft] = key.split('_');
      if (ft === fuelType) {
        dataset.data.push(value);
      }
    });

    lineData.datasets.push(dataset);
  });
  const legendData1 = [...fuelTypes].map((type: any, index: any) => ({
    name: type,
  }));


  const useTypes = new Set();

  // Group the data by period and fuel type
  const groupedData2 = updatedData.reduce((result: any, item: any) => {
    const period = item.Period;
    const use = item.Use;
    const quantity = item.Quantitym3 || 0;

    const key = period + '_' + use;
    if (result[key]) {
      result[key] += quantity;
    } else {
      result[key] = quantity;
    }

    useTypes.add(use);

    return result;
  }, {});

  // Generate the line chart data for each fuel type

  const colorScaleLine2 = scaleOrdinal(schemeCategory10);

  const lineData2 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: [],
  };

  // Generate the line chart data for each fuel type
  useTypes.forEach((use: any, index: any) => {
    const dataset = {
      data: [],
      color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
      strokeWidth: 2,
    };

    Object.entries(groupedData2).forEach(([key, value]) => {
      const [period, ft] = key.split('_');
      if (ft === use) {
        dataset.data.push(value);
      }
    });

    lineData2.datasets.push(dataset);
  });
  const legendData2 = [...useTypes].map((useType: any, index: any) => ({
    name: useType,
  }));


  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }
  function handleYearFilterSelect(val: string) {
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedYear(val);
  }
  function handleMonthFilterSelect(val: string) {
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedMonth(val);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
        //setLogData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };
  const fuleTypeLabels = LogColumns.find((col: any) => col.columnName === 'FuelTypeEnergySource')?.choiceChoices;
  const fuelTypesTable = fuleTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });
  const useTypeLabels = LogColumns.find((col: any) => col.columnName === 'Use')?.choiceChoices;
  const useTypesTable = useTypeLabels.split("#;#").map((value: string) => { return value.split("#;#")[0] });

  const groupedDataTable = newData.reduce((result, item) => {
    const { Period, FuelTypeEnergySource, Use, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      fuelTypesTable.forEach((fuelType: any) => {
        result[Period][fuelType] = 0; // Initialize with 0 for each FuelTypeEnergySource
      });
    }
    result[Period][FuelTypeEnergySource] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each FuelTypeEnergySource within each Period
  const transformedDataTable = Object.values(groupedDataTable).map((values: any) => {
    const sumByFuelType = fuelTypesTable.reduce((sum: number, fuelType: string) => sum + values[fuelType], 0);
    return {
      ...values,
      "Total (Fuel Type)": sumByFuelType
    };
  });



  const groupedUseDataTable = newData.reduce((result, item) => {
    const { Period, Use, Quantitym3 } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      useTypesTable.forEach((useType: any) => {
        result[Period][useType] = 0; // Initialize with 0 for each FuelTypeEnergySource
      });
    }
    result[Period][Use] += Math.round(Quantitym3);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each FuelTypeEnergySource within each Period
  const transformedUseDataTable = Object.values(groupedUseDataTable).map((values: any) => {
    const sumByUseType = useTypesTable.reduce((sum: number, useType: string) => sum + values[useType], 0);
    return {
      ...values,
      "Total (Use Type)": sumByUseType
    };
  });


  // Create an object to store the combined data
  const combinedData: { [key: string]: { [key: string]: number } } = {};

  // Merge datasets based on Period
  transformedDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });

  transformedUseDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });

  // Convert the combinedData object back to an array
  const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
    Period,
    ...values
  }));


  const columnKeys = Object.keys(mergedData[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  });

  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");
  const TotalCount = newData.reduce((total, item) => {
    if (item.Quantitym3) {
      return total + item.Quantitym3 || 0;
    }
    return total;
  }, 0);
  return (
    <View style={logStyles.logRoot}>
      
      <View style={[CommonStyles.ribbonIconContainer, { justifyContent: 'center' }]}>
        <View style={[CommonStyles.IconContainer, { justifyContent: 'center' }]}>
          <FilterIcon setFilterVisible={setFilterVisible} FilterVisible={FilterVisible} />
        </View>
        <View style={[CommonStyles.IconContainer, { justifyContent: 'center' }]}>
          {
            EnableExport() === true ?
              <ExportIcon handleExportClick={handleExportClick} newData={newData} />
              : null
          }
        </View>
        <SelectedFilters cardTitle='Total Fuel (m3)' number={TotalCount} selectedMonth={selectedMonth} selectedPackage={selectedPackage} selectedYear={selectedYear} />


        
      </View>
      <View style={[{justifyContent: 'center', alignItems:'center'}]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Fuel (m3)'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={FilterVisible}
        onRequestClose={() => {
          setFilterVisible(!FilterVisible);
        }}>
        <View style={formCustomStyles.centeredView}>

          <FilterBox
            FilterVisible={FilterVisible}
            setFilterVisible={setFilterVisible}
            screenWidth={screenWidth}
            CommonStyles={CommonStyles}
            yearFilterOptions={yearFilterOptions}
            handleYearFilterSelect={handleYearFilterSelect}
            selectedYear={selectedYear}
            monthFilterOptions={monthFilterOptions}
            handleMonthFilterSelect={handleMonthFilterSelect}
            selectedMonth={selectedMonth}
            packageFilterOptions={packageFilterOptions}
            handlePackageFilterSelect={handlePackageFilterSelect}
            selectedPackage={selectedPackage}
            handleFilterReset={handleFilterReset}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            setSelectedPackage={setSelectedPackage}
            setNewData={setNewData}
            updatedData={updatedData}
          />
        </View>
      </Modal>
      <View>
        <ToastMessage
          show={toastVisibility}
          actionLabel="Dismiss"
          toastMessage={toastText}
          duration={3000}
          onDismiss={handleToastDismiss}
        />
      </View>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                  <View style={[CommonStyles.container, CommonStyles.divider]}>
                    <View key={1} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byFuelTypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Fuel Qty(m3) / Type"} /></View>
                    <View key={2} style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Fuel Qty(m3) / Type"} /></View>
                  
                    <View key={3} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byUsePieData} colorPallate={interpolateGnBu} subTitle={'(Cumulative)'} ChartTitle={"Fuel Qty(m3) / Use"} /></View>
                    <View key={4} style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData2} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Fuel Qty(m3) / Type"} /></View>
                  </View>
                </View>
            </ScrollView>
          </TabScreen>

          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View>
                <View style={CommonStyles.container}>
                  <View style={CommonStyles.cardTwo}>
                    {
                      newData &&
                      newData.length > 0 &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={true}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>



    </View>
  );
};




