import React from 'react';
import { View, StyleSheet, Dimensions, Text ,ScrollView} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { useAuth } from '../providers/AuthProvider';
import Login from './Login/Login';
import Home from './Home';

//import { ScrollView } from 'react-native-gesture-handler';
const Landing: React.FC = () => {
    const { accessToken } = useAuth();
    return (
        <View style={styles.container}>
            {/* <ScrollView contentContainerStyle={{ flexGrow: 1 }} scrollEnabled={true} nestedScrollEnabled={true}> */}
                <View style={styles.viewInScrollView}>
                    {accessToken ?
                        <Home />
                        // <TestLongComp />
                        :
                        <Login />
                    }
                </View>
                
            {/* </ScrollView> */}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        //backgroundColor: '#F0F0F0', 
        flex: 1,
    },
    viewInScrollView: {
        //marginTop: 40,
        //marginBottom: 20,
        flexGrow: 1,     
        alignItems: 'center',
        
    },
    logo: {
        width: 200,
        height: 60,
        resizeMode: 'contain'
    }

});

export default Landing;
