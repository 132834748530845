import React, { useState } from 'react';
import { Button, Platform, View, Text } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import ButtonControl from './Button';
import { upload } from '../service/dataOperations';
import { useAuth } from '../../../../providers/AuthProvider';
import { IconButton, MD3Colors, Portal } from 'react-native-paper';
import { Loader } from '../../../Common/Loader';
import ConfirmBox from '../../../Common/ConfirmBox';
interface AttachmentProps {
  onFileUploaded: (file: { fileId: string, fileName: string }) => void;
  clientAPIURL: string;
  showWarning: any;
  warningText: any;
  formMode:string;
}
const DocPicker: React.FC<AttachmentProps> = ({formMode, onFileUploaded, clientAPIURL, showWarning, warningText }) => {
  const [doc, setDoc] = useState<any>(null);

  const { getAccessToken } = useAuth();
  const [fileLoad, setFileLoad] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const pickDocument = async () => {
    setFileLoad(false);
    let result: any = await DocumentPicker.getDocumentAsync({ type: ['image/*', 'application/pdf'],multiple:false });
    if(Platform.OS === 'web' || Platform.OS === 'ios' || Platform.OS === 'android'){result = result.assets[0] && result.assets[0]}
    console.log(result);
    showWarning(false);
    const docType = result.name && result.name.split('.').pop().toLowerCase(); //result.name && result.name.split('.').pop().toLowerCase();
    
    if (docType && docType === 'pdf' || docType === 'jpg' || docType === 'png' || docType === 'jpeg') {
      console.log(docType);
      //if (result.file.size && result.file.size > '5242880') {
        if (!result.uri) {
          showWarning(true);
          //warningText("Selected is too large to upload. Maximum allowed file size is 5MB.");
          warningText("Something went wrong with selected file. Please try again.");
          return false
        }
      else {
        if (result.name.includes(' ')) {
          const modifiedName = result.name.replace(/ /g, '_');
          result.name = modifiedName;
        }
        setDoc(result);
        console.log(result)
      }
    }
    else {
      setDoc(null);
      showWarning(true);
      warningText("You can upload only images and pdf files.");
    }
  }

  const postDocument = async () => {
    const url = "https://dev-dgda-client-greenport.azurewebsites.net/api/uploadFile";
    const fileUri = doc.uri;
    const formData = new FormData();
    formData.append('document', doc);
    const options = {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log(formData);
    const response = await fetch("https://dev-dgda-client-greenport.azurewebsites.net/api/uploadFile", options);
    //fetch(url, options).catch((error) => 

    console.log(response)
  }

  const uploadDoc = async () => {
    setFileLoad(true);
    //var formdata = new FormData();
    //formdata.append("file", doc, "plumsail-form-2023621-12410.pdf"); // The third parameter is the filename you want to use on the server-side
    //var myHeaders = new Headers();
    //myHeaders.append("Content-Type", "multipart/form-data");
    var myHeaders = new Headers();
    const accessToken = await getAccessToken();
    myHeaders.append("Authorization", `Bearer ${accessToken}`)
    var formdata: any = new FormData();
    let xhr = new XMLHttpRequest();
    if (Platform.OS == "web") {
      formdata.append("file", doc.file, doc.name);
    }
    else if (Platform.OS == "ios") {
      formdata.append("file", doc);
      myHeaders.append("Content-Type", "multipart/form-data");
    }
    else {
      formdata.append("file", {
        uri: doc.uri,
        type: doc.mimeType,
        name: doc.name,
      });
    }

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    // fetch("https://dev-dgda-client-greenport.azurewebsites.net/api/uploadFile", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
    try {

      xhr.open("POST", clientAPIURL + "/api/uploadFile");
      xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
      xhr.send(requestOptions.body);
      xhr.onload = function () {
        setFileLoad(false)
        if (xhr.status != 200) { // analyze HTTP response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { // show the result
          console.log(`Done, got ${xhr.response}`); // response is the server response
          successCall(xhr);
        }
      };

      xhr.onerror = function () {
        setFileLoad(false)
        console.log("Request failed", xhr.status, xhr.statusText, xhr.responseText);
      };

      xhr.onreadystatechange = function () {
        console.log("State change", xhr.readyState, xhr.status, xhr.statusText);
        if (xhr.readyState === 4) {
          console.log("Complete response", xhr.responseText);
        }
      };
    }
    catch (ex) {
      console.log(ex);
    }
  }
  const successCall = (result: any) => {
    onFileUploaded(result.response);
    setDoc(null);
  }
  async function DeleteConfirmation(){
    setDoc(null);
    setFileLoad(false);
    setShowConfirmation(!showConfirmation);
  }
  async function handleDeletePress() {
    setShowConfirmation(!showConfirmation);
  };
  return (
    <View>
      <View>
        <View>
          <></>
          <Text>Allowed Document types are: JPG, PNG, JPEG and PDF.</Text>
          <Text>Maximum Allowed File Size: 5MB</Text>
        </View>
        {formMode === "View" ? null :
          <View style={{ width: 200, paddingTop:20 }}>
            <ButtonControl icon="paperclip" mode="contained" Title="Select Document" onPress={pickDocument} />
          </View>
        }
      </View>


      {
        doc === null ? null :
          <View>
            <View style={{ flexDirection: "row", alignItems: "center", height: 50 }}>
              <Text>Selected File: {doc.name}</Text>
              <IconButton
                icon="close-circle-outline"
                iconColor={MD3Colors.error50}
                size={20}
                onPress={() => {handleDeletePress()}}
              />
            </View>
            {
              fileLoad === true ?
                <Loader size={'small'} />
                :
                formMode === "View" ? null :
                  <View style={{ width: 200 }}>
                    <ButtonControl icon="upload" mode="contained" Title="Upload" onPress={uploadDoc} />
                  </View>
            }

           
          </View>
      }
       {showConfirmation === true ? (
              <View>
                
                  <ConfirmBox 
                    title={"Confirmation"}
                    message={"Are you sure you want to proceed to delete selected items?"}
                    yesCallback={DeleteConfirmation}
                    noCallback={handleDeletePress} />
                
              </View>
            ):null}
    </View>
  )
};

export default DocPicker;